@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600&display=swap');

html {
    -webkit-text-size-adjust: none;
    -webkit-appearance: none;
}

html, body.order_page {
    min-width: 330px;
    background: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #000101;
    line-height: 1.2;
}

body {
    overflow-x: hidden;
}

.order_page input, textarea, select, button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #000101;
    -webkit-appearance: none;
}

.order_page input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #999;
}

.order_page input::-moz-placeholder, textarea::-moz-placeholder {
    color: #999;
    transition: color 0.3s;
}

.order_page input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
    color: #eee;
}

.order_page input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
    color: #eee;
}

.order_page .site-width {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1240px;
    box-sizing: border-box;
}

.order_page .nowrap {
    white-space: nowrap;
}

.order_page .button {
    font-size: 20px;
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    border: #bbb9b9 1px solid;
    background: #d4d3d3;
    border-radius: 25px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}

.order_page button {
    cursor: pointer;
    outline: 0;
}

.order_page button[type="submit"] {
    font-size: 20px;
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    font-weight: 600;
    border: #f99f1e 1px solid;
    min-width: 200px;
    background: #f99f1e;
    border-radius: 25px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}

.order_page button[type="submit"]:disabled {
    background: #a3a3a3;
    border-color: #a3a3a3;
    cursor: default;
}

.order_page .login-block {
    padding: 15px;
    padding-left: 40px;
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    position: relative;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .login-block ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.order_page .login-block ul li {
    margin: 12px 10px 0 0;
    padding: 0;
    font-size: 11px;
    width: calc(50% - 10px);
}

.order_page .login-block .login-link {
    display: block;
    margin-left: 10px;
    order: 3;
    display: inline-flex;
    align-items: center;
    color: #000101;
    text-decoration: none;
}

.order_page .login-block .login-link:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC8UlEQVRIib2WXWiOYRjHf+/TDmRmaT4jYSvLx1ZEL1uIWlk5MEucSMPkBMm3Ew4kR8iJhgM5YNIcqBEitdmyWphmEQ44UDP1GrGo6er5P553z32/7xz519tb13Xd/+u+r88nVV9fTx6kgGVAHVANlAPFMs8AfUAb0AJ0AMO5qAJHEqMW6BZRGrgLNACr9GuQLC2bbp3xosAjLAKagI3AZb3mvWMVwl5yHJgNHAFuAzeARmAw2zDpaCpwDyjUTbscahgHzAeWA5+BW7qIkV8ErgPtQA3wyRe6IjkZ8jipBB4CP3XTTmAncAboAabLrktnh8RV5HPUpJdYnPuz5JOBR8B3hXGJZFYYs4AB4HyWfb84CsU5InS1ykk64cSwRRW2Hvid0H0DDqsoxgNfs5xt0suvAq2BSvikEu/LyVLgvsdJhHZxLEzIu8Rp3KkC9UmlwuJDCdDrkUf4obyVOBo4Bbw1H4EcPMlTwlYAYx2pi5QjCTmNuy5Qxz9wTGJ8ASY60hgp5SfjaEIYd3Wg6nnhqGNY2BY50hhz5OyDowlh3OWBZteAo45hpV0hQh9qVGXvPDrEXZxv1kXo1Kv2OJqwPfYBV/INVNSwmVFyYDgB7AJmJuTbNbZOOydiGHcm0KivcNQjcRN4A+xIyPcD5zTzcsG4+wKN+DU5jCIMy25eQl6qHOaDcbcFGvVVQFke4xnAWuBZQm47aC8wxjkRokzcLYE2oxEccsxgikbIK+AjcDah3wos1gTf4JwOOY27I1BYjgHbNFRRNR3V+LDheABYmVxmcmAz7o72kP1Pki4tTuMejsq7FWgGrmmpWdwPqnTnAheAX859Q1if7Na8tL30FFghrmZxj9hH0fp9qS1aqX2Sa2on0aut+xp4LK7GyCbb0aC63MIxAZjmUI2OBYpAj7gGfY7Qjq9SgbTrG6D0HxyYzSWdsbPG8fd7weD7CrJbbNZmtIqzRjUCm8IW1qg5reOtGVeL+DmwLspJEv/nAxL4A/TAsmOMOcA8AAAAAElFTkSuQmCC");
}

.order_page .login-block .form {
    text-align: center;
    margin: 0 0 0 auto;
}

.order_page .login-block .form .form-link {
    margin-top: 10px;
    font-size: 14px;
}

.order_page .login-block .form a {
    text-decoration: none;
    font-weight: 600;
    color: #f99f1e;
    font-size: 14px;
}

.order_page .login-block .form a:hover {
    text-decoration: underline;
}

.order_page .login-block .form button[type="submit"] {
    font-size: 18px;
    padding: 12px 30px;
    border-radius: 24px;
    min-width: 190px;
}

@media (max-width: 800px) {
    .order_page .login-block {
        flex-direction: column;
        padding-left: 15px;
    }

    .order_page .login-block .login-link {
        margin-left: auto;
        order: 0;
    }

    .order_page .login-block .login-link:before {
        content: 'Войти';
    }

    .order_page .login-block ul {
        margin-right: 0;
    }

    .order_page .login-block .form {
        margin: 20px auto 0;
    }
}

@media (max-width: 600px) {
    .order_page .login-block ul {
        display: block;
    }

    .order_page .login-block ul li {
        margin-right: 0;
        width: auto;
    }
}

@media (max-width: 400px) {
    .order_page .login-block .login-link {
        margin-bottom: 10px;
    }
}

.order_page .logout-block {
    margin: 20px 0;
    padding: 5px 15px;
    text-align: right;
}

.order_page .logout-block a {
    color: #000101;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.order_page .logout-block a:hover {
    text-decoration: underline;
}

.order_page .logout-block a:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC40lEQVRIiZ2Wy0sXcRTFPzM/e1oJUdBjFRhIQQYRWFoUQQuRFkGiqyGD/gV7bJP2LaKMoB8URQvbhEREFJkKQkVEubGCCAKVMCuoxIk7c37N4ztj2YFZ/O69v3Pmfh/njheGIaWoeh6wBzgKtAFNQIPKZ4BxYAgYAEaALFmQ/PTLNKh67cAzEbUA94Ae4ICeHsVaVGO17Q6P4HZU9VYD/UAncBU4D7xz/pnFFuA0cAK4DZwEZtMdZYWq3gbgPlAPdAFjDiWsArYDe4Ep4A7wVbndwC3gG3CYIPzkCsWdPAV+REsQhJNUvVpdM3BB+7VEMSO3gmmJflR8PTAILANaCcLZ/B71q5NYJIF1+QjYClRyna0E1gCXUvFJ7VW9OEmE4o3vjJYrK2I4LoGNBYfH0ylsl2BarCvijLnxdYT7oo0PwqI9OQiscKJZMXt25uJjOkx9plGndW/WXSnCZqCuIJ7GvDrOw07shGn4EhgmCMuO8C/nIhZjqROOOYdNw9eNf+AUJZjSGy8E28MvJXnjbvNlKy+ddILnwJwTdTHhRGIYd5OvUzPtpBPU7kQZQt2p1yV5424o97oEj4G3wE8nE2NObrDg8vpy4XVOJoERnEs5Qhqh8mecTALjnvFl9TucdBbX9UJ5mF1d1AUtg3GP+7L4QyVFNVREmsdy4KETzcK4h3wNrVaqXqNTEsOWrFtmmce8fG6fkyGytsaIGwZ8TcYXQK9TCJuAG7KSooPjyznMdC/LitLoFfdIPCZi47sb2X0QjlL1rItTwFnZT8WRKMYHYD/wXpPXXKGDIBxMz6ObSnYAV4BdEinqpAyhDoZNgmvAKEHYTY4kHr/wSktWWaQIWrrPWspZccaJklFuTrz2P4S+y/Mm86M8SxQnWvVG3j96XA3m8ja37LoYxx8RVygWs6+XY8ARWU+NxESt/ZobWMzuVu1+manayLH/Rt8JaSz2A3IbYB8xBiN7Azz56wck8Bsa+daUDSJ8VQAAAABJRU5ErkJggg==");
}

.order_page .reserv-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .reserv-block .reserv-h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.order_page .reserv-block .reserv-info {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
    padding: 15px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
}

.order_page .reserv-block .reserv-summary {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
    display: none;
    justify-content: space-between;
}

.order_page .reserv-block .reserv-price {
    white-space: nowrap;
}

.order_page .reserv-block .reserv-h2 a {
    color: #000101;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.order_page .reserv-block .reserv-h2 a:hover {
    text-decoration: underline;
}

.order_page .reserv-block .reserv-h2 a i {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    border-radius: 50%;
    background: #f5f5f5;
    position: relative;
    flex-shrink: 0;
}

.order_page .reserv-block .reserv-h2 a i:before {
    position: absolute;
    left: 40%;
    top: 50%;
    display: block;
    width: 8px;
    height: 8px;
    border: #353535 1px solid;
    border-top: 0;
    border-right: 0;
    transform: translateY(-50%) rotateZ(45deg);
    content: '';
}

@media (max-width: 800px) {
    .order_page .reserv-block {
        padding: 20px 15px;
    }
}

@media (max-width: 600px) {
    .order_page .reserv-block .reserv-info .reserv-price {
        display: none;
    }

    .order_page .reserv-block .reserv-summary {
        display: flex;
    }
}

.order_page .flight-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .flight-block .flight-md {
    display: none;
}

.order_page .flight-block .flight-h1 {
    font-size: 28px;
    font-weight: 600;
    color: #FFA000;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.order_page .flight-block .flight-h1 i {
    display: block;
    width: 27px;
    height: 24px;
    margin-right: 10px;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAYCAYAAAALQIb7AAABpUlEQVRIib2VTStEURjHf8MoaoQwjNd8AVsbH0EWsrGWppCokayVBStZeImywwdgo6SwlKxGkQViYaO8LFCjZ+bIdJ4z956p4VdTc/7nf+7/Ps85995IZgsfFoBp4BEYAfa8VlmUKcVNv1ETwDbQ4HSF4BsWy/tfDaSUo4Rh99Z4DKhVrhB8w26tsVQ6qVwh+IbdKQUmrPZ6hR0CN8CQmv3FrkyoA8aVGoCE9QBdwArQXMDqChOmgCqlFkDCrs1UDbDstjnbKMSBpFILEDVh3WZ6EBgGzsw4Zjz17uVZUuZhd1VYAWSAUyAtF7qyDBtqSTCtwE6gIxc4k9/GvyQCzEnY6z+ECU8S1qHk0pMGBmTP2q1LbwInwCfwZrRK8wJ28QD0mX15Bz4sz7P5ZU9afmVyB6OOBZ2OkB+WgHOlOpA2tuXJSUeQ0KSUHLLf60otgISVm6lV4Nhto1EpOdZ+WuSDtLHXVBf0CLQoBb5MC72JmlZchixwVbYb8Bpz4vuJSSgFFpUSgm9Y3BofABfKVaIw+xDMFxtUTNi+ORAvwCxwVHQS8A1s1EQaVkakLAAAAABJRU5ErkJggg==");
}

.order_page .flight-block2 .flight-h1 i {
    transform: rotateZ(180deg);
}

.order_page .flight-block .flight-h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.order_page .flight-block .flight-h2 img {
    vertical-align: middle;
    margin-left: 10px;
}

.order_page .flight-block table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.order_page .flight-block table th {
    border-bottom: #a3a3a3 1px solid;
    vertical-align: top;
    font-size: 10px;
    font-weight: 500;
    padding: 10px;
}

.order_page .flight-block table td {
    vertical-align: top;
    font-size: 15px;
    padding: 25px 10px 0;
}

.order_page .flight-block table td small {
    font-size: 12px;
}

.order_page .flight-block .flight-table-2 th, .flight-block .flight-table-2 td {
    width: 50%;
}

.order_page .flight-block .flight-table-3 th, .flight-block .flight-table-3 td {
    width: 33.333%;
}

.order_page .flight-block .flight-more {
    font-size: 16px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.order_page .flight-block .flight-more:after {
    flex-grow: 1;
    display: block;
    margin-left: 10px;
    height: 1px;
    background: #a3a3a3;
    content: '';
}

.order_page .flight-block .flight-more a {
    color: #000101;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.order_page .flight-block .flight-more a:after {
    display: block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    margin-top: -7px;
    transform: rotateZ(-45deg);
    border: #353535 1px solid;
    border-right: 0;
    border-top: 0;
    cursor: pointer;
    content: '';
}

.order_page .flight-block .flight-more.flight-more-active a:after {
    transform: rotateZ(135deg);
    margin-top: 7px;
}

.order_page .flight-block .flight-more + table {
    display: none;
}

.order_page .flight-block .flight-more.flight-more-active + table {
    display: table;
}

.order_page .flight-block .gallery {
    display: flex;
    align-items: center;
}

.order_page .flight-block .gallery-slider {
    position: relative;
    margin-left: 50px;
}

.order_page .flight-block .gallery-slider-inner {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.order_page .flight-block .gallery-slider-inner img {
    display: block;
    width: 32%;
    height: 120px;
    object-fit: cover;
}

.order_page .flight-block .gallery-slider a {
    position: absolute;
    top: 50%;
    display: block;
    width: 10px;
    height: 10px;
    border: #353535 1px solid;
    border-top: 0;
    cursor: pointer;
}

.order_page .flight-block .gallery-slider .gallery-slider-prev {
    left: -20px;
    transform: translateY(-50%) rotateZ(45deg);
    border-right: 0;
}

.order_page .flight-block .gallery-slider .gallery-slider-next {
    right: -20px;
    transform: translateY(-50%) rotateZ(-45deg);
    border-left: 0;
}

@media (max-width: 1200px) {
    .order_page .flight-block .gallery > img {
        display: none;
    }

    .order_page .flight-block .gallery-slider {
        margin: 0 20px;
    }
}

@media (max-width: 900px) {
    .order_page .flight-block .flight-lg {
        display: none;
    }

    .order_page .flight-block .flight-md {
        display: block;
    }

    .order_page .flight-block table {
        margin-bottom: 30px;
    }

    .order_page .flight-block table td {
        padding-top: 15px;
    }
}

@media (max-width: 800px) {
    .order_page .flight-block {
        padding: 20px 15px;
    }
}

@media (max-width: 500px) {
    .order_page .flight-block .gallery-slider-inner img {
        width: 100%;
    }
}

.order_page .noty-block {
    padding: 10px 70px;
    margin: 20px 0;
    border-radius: 20px;
    border: #a3a3a3 1px solid;
    display: flex;
    box-sizing: border-box;
    max-height: 130px;
    overflow: hidden;
    position: relative;
}

.order_page .noty-block > div + div {
    margin-left: 50px;
}

.order_page .noty-block .noty-h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    padding-bottom: 5px;
    position: relative;
}

.order_page .noty-block .noty-h2:after {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    max-width: 320px;
    width: 100%;
    height: 1px;
    background: #a3a3a3;
    content: '';
}

.order_page .noty-block .noty-text {
    font-size: 9px;
    line-height: 1.4;
}

.order_page .noty-block .noty-more {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotateZ(-45deg);
    border: #353535 1px solid;
    border-right: 0;
    border-top: 0;
    cursor: pointer;
}

.order_page .noty-block.noty-block-expanded {
    max-height: unset;
}

.order_page .noty-block.noty-block-expanded .noty-more {
    transform: rotateZ(135deg);
    bottom: 12px;
}

@media (max-width: 800px) {
    .order_page .noty-block {
        display: none;
    }
}

.order_page .order-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .order-block .rules {
    display: flex;
    margin-bottom: 40px;
}

.order_page .order-block .rules-company {
    padding-left: 40px;
    position: relative;
}

.order_page .order-block .rules-company:before {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: #f5f5f5;
    content: '';
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==");
}

.order_page .order-block .rules-company + .rules-company {
    margin-left: 60px;
}

.order_page .order-block .rules-h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: -40px;
    display: flex;
    align-items: center;
}

.order_page .order-block .rules-h2:before {
    display: block;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 8px;
    background: #f5f5f5;
    flex-shrink: 0;
    content: '';
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==");
}

.order_page .order-block .rules-link {
    font-size: 11px;
    margin-top: 5px;
}

.order_page .order-block .rules-link a {
    color: #000101;
    text-decoration: underline;
}

.order_page .order-block .continue {
    display: flex;
    justify-content: space-between;
}

.order_page .order-block .continue a {
    color: #000101;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.order_page .order-block .continue a:first-child {
    margin-right: 10px;
}

.order_page .order-block .continue a:last-child {
    margin-left: 10px;
    text-align: right;
}

.order_page .order-block .continue a:hover {
    text-decoration: underline;
}

.order_page .order-block .continue a i {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f5f5f5;
    position: relative;
    flex-shrink: 0;
}

.order_page .order-block .continue a:first-child i {
    margin-right: 20px;
}

.order_page .order-block .continue a:last-child i {
    margin-left: 20px;
}

.order_page .order-block .continue a i:before {
    position: absolute;
    top: 50%;
    display: block;
    width: 8px;
    height: 8px;
    border: #353535 1px solid;
    border-top: 0;
    content: '';
}

.order_page .order-block .continue a:first-child i:before {
    left: 40%;
    transform: translateY(-50%) rotateZ(45deg);
    border-right: 0;
}

.order_page .order-block .continue a:last-child i:before {
    right: 40%;
    transform: translateY(-50%) rotateZ(-45deg);
    border-left: 0;
}

.order_page .order-block .form {
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .order_page .order-block {
        padding: 20px 15px;
    }
}

@media (max-width: 700px) {
    .order_page .order-block .rules {
        display: block;
        margin-bottom: 20px;
    }

    .order_page .order-block .rules-company:before {
        display: block;
    }

    .order_page .order-block .rules-company + .rules-company {
        margin-left: 0;
        margin-top: 20px;
    }

    .order_page .order-block .rules-h2 {
        margin-left: 0;
        margin-bottom: 5px;
    }

    .order_page .order-block .rules-h2:before {
        display: none;
    }
}

@media (max-width: 700px) {
    .order_page .order-block .continue a {
        font-size: 16px;
    }
}

.order_page .payments {
    margin-bottom: 30px;
    padding-left: 75px;
}

.order_page .payments .payments-item {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: #c5c5c5 1px solid;
    position: relative;
}

.order_page .payments .payments-item b {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.order_page .payments .payments-item b:last-child {
    margin-bottom: 10px;
}

.order_page .payments .payments-item a {
    color: #000101;
    text-decoration: none;
    margin-left: 30px;
    display: inline-block;
}

.order_page .payments .payments-item a:hover {
    text-decoration: underline;
}

.order_page .payments .payments-item img {
    display: block;
    position: absolute;
    left: -75px;
    top: 0;
}

.order_page .payments .payments-item small {
    display: block;
    color: #868686;
    margin-bottom: 10px;
    font-size: 10px;
}

.order_page .payments .payments-item input {
    display: block;
    color: #424242;
    font-size: 12px;
    border: 0;
    background: #eee;
    border-radius: 5px;
    padding: 10px;
    flex-grow: 1;
}

@media (max-width: 500px) {
    .order_page .payments .payments-item.payments-item-link b {
        display: block;
    }

    .order_page .payments .payments-item.payments-item-link input + a {
        margin: 5px 0 0 0;
    }
}

.order_page .form {
}

.order_page .form .form-control {
    margin-bottom: 20px;
}

.order_page .form .form-checkbox {
    display: flex;
    justify-content: space-between;
}

.order_page .form .form-checkbox label {
    font-size: 16px;
}

.order_page .form .form-buttons {
    display: inline-block;
}

.order_page .form .form-submit {
    text-align: center;
    margin-top: 30px;
}

.order_page .form .form-submit button {
    min-width: 260px;
}

.order_page .form .form-policy label {
    font-size: 11px;
    align-items: center;
}

.order_page .form .form-policy a {
    color: #000101;
    text-decoration: underline;
}

.order_page .form button + button {
    margin-left: 10px;
}

.order_page .form .form-links {
    display: inline-block;
    float: right;
    margin-top: 10px;
}

.order_page .form .form-links a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #000101;
}

.order_page .form .form-links a + a {
    margin-left: 30px;
}

.order_page .form .form-links a:hover {
    text-decoration: underline;
}

.order_page .form .form-links a:before {
    flex-shrink: 0;
    margin-right: 10px;
    width: 33px;
    height: 33px;
    display: block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    content: '';
}

.order_page .form .form-links a.form-links-share:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAaCAYAAACtv5zzAAABgUlEQVRIibXWwUtUURTH8Y9agpBkrURECnQjgX9Bf0DUX5AuXEcboZWbclWu/AOildAikRbtok3rdi2i0IWuTBc1mZKEWRw4wmteMvnmzhced85wz2/m3Pe791wFmcZbnKCFxxgoJT+Cz/iNj/ian5drMxsyn4JPM300q2j1F/qBSzl+ynEP3zBcm3lOhrCI/awgRB/hZcavmwr3YQ7bKXSA5zjOOJ4NXK9l/gc38S5FwjHPMJZpE7iLWxg8r/AU1iv/8A1marMacBUr+JnCH3C7idA4buBixlHiAr6kcDjjHi7UMjtwGa8qpe+kMzYz/oEnOa8Rqyn0HmttTghnXOt2jY+wm54OllJ8sTazAac7uS8flbEYqxV3xA78VXqJznrJGxkfdfuST+mZTTtx5R8b7U6HnEZM9uqoaKdnh12VsPJs5bg+LHlcV2lvONEmH+JFtw2nnfsp+CC/jwq3Yk+V6snR0YK4ugTR9MN93wvp/3VtibWP3lz02hLUL14M/AHNFHsGsLHuDAAAAABJRU5ErkJggg==");
}

.order_page .form .form-links a.form-links-print:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAB00lEQVRYhe2Yv0rEQBDGf6OCCnLYKhaWgmDnaSOoiL1PEBBszkYQRKzOB1AEO+HAztJWLBSxtBNEn+AEG8HGxnAyMisx2ZiLJnrFfTAkl83OfJlv/8weIpJmhyISikirAAvNny+OmuCBPnwDboErT3tezALTwBDwmujrYeVMv+DA8/wnVjd/w76+PQlW/4AuCYcuCYeOIOGmSUVE9kSkWdDilGVNi1dxi1UFuAamgEtboMqGxlqwWHMYI2UX+BaSEi2wuBpfmsADsPgPo+ECmNCBOVKCBJPAjl0VvcCaWW/kvRuN35foXgzOgVFgHRgDVoEj8yyR+4/NrIwp6tuWfc8+UUYmWsAysAKc2u9GpL0R71CWHHdmDmFEggS6y7ZDx5M4NC1bBVho/rxIG5g6pWoFF7rqb8tX6KaRaFmWlMBGojU/6sAM0O8j8Z0ccdTakGU90asNpGXCB93m92NrfxShbUh5MICReLT9PQv3wGbGO3lR1fgqx4kVGEGGg3bkyCNNYHFPNBO7wBJwbA03vsHThhxRpEmzbddqpLLazaoxiz4GOvtSY7qB+WJ6qw3aVHpOfMfvMQ48xTOddipXaEpVmrMCgs9/FLQ/OJX/zf8TIrwD0rK9GSISrn4AAAAASUVORK5CYII=");
}

.order_page .form .form-bottom {
    font-size: 10px;
    margin-top: 30px;
    line-height: 1.4;
}

.order_page .form .form-bottom a {
    color: #000101;
    text-decoration: none;
}

.order_page .form .form-bottom a:hover {
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .order_page .form .form-links {
        display: block;
        float: none;
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .order_page .form .form-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .order_page .form button + button {
        margin: 20px 0 0 0;
    }

    .order_page .form .form-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .order_page .form .form-links a + a {
        margin: 20px 0 0 0;
    }
}

.order_page .control-checkbox {
    position: relative;
    cursor: pointer;
    font-size: 18px;
    display: inline-flex !important;
    align-items: center;
}

.order_page .control-checkbox input[type="checkbox"], .control-checkbox input[type="radio"] {
    display: none;
}

.order_page .control-checkbox input[type="checkbox"] + i, .control-checkbox input[type="radio"] + i {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: #a3a3a3 1px solid;
    background: #f3f3f3;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 10px;
}

.order_page .control-checkbox input[type="checkbox"]:checked + i:before, .control-checkbox input[type="radio"]:checked + i:before {
    position: absolute;
    display: block;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f99f1e;
    content: '';
}

.order_page .modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    align-items: center;
    justify-content: center;
}

.order_page .modal.modal-active {
    display: flex;
}

.order_page .modal:before {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    content: '';
}

.order_page .modal .modal-window {
    border-radius: 20px;
    position: relative;
    max-width: 95%;
    max-height: 90%;
    min-width: 400px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    overflow: auto;
}

.order_page .modal .modal-body {
    background: #fff;
    border-radius: 20px;
    padding: 40px 100px;
    box-sizing: border-box;
    position: relative;
    font-size: 13px;
}

.order_page .modal .modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 21px;
    height: 21px;
    z-index: 1;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAB2UlEQVQ4ja3VTYjNYRTH8c9cTCiyUDIbZSVZ2SgpSUpThDBS5CVFmY23QnbTKG8r4yWJvJSkKJlQQlazuSvKSrEYWSiljJdm6OTc5vb/32vuNH67+5xzv/c55/zOc9uq1epCXMFWvDcxzcXHCs5hKV5i/gSQa/AOewO6BQOYh1dYUEofWxtxD1OxKKBfsCqBHXgRgXEAo2130I6z6K5k4CtW4xnm4DkWl75e1m7cwCScxCH8rtSlfcu+9GN2/sCSEmZU+3LAATyBY7VIpZA4hPV4gFl4imUlHAfQhzYcQU99sAgN/cRm3MVMPMHKuvjR7F2oG6eLgMkl5F/9ygF8x3Y8xIa0XpQ6EtbJ8ktqBg0NYyd+YA8eZWVxvisH1FCNyq9X7Uav63Iv/guoBWjEL6VvR/Ispr6tlNkiNKxyLUsfSrv15Pn1PG+oZj2dgpvoSv+uTd/25+deXM4t6mvlpu1pp67CptVU25zQeRweCzoN97Gu8CYUFT7dHyuJUzjeDDo9/diJz2n4gRJuVBeyr8PZ694idAYeJ+gTVqBawpR1FTsSHJt2JlY3BhU7HsB4PAYT/LYFYE23cvNu42C8qXHTeAsD+AHLxwmsKR7oTbl9b/73f1QHBv8AmJdqImTRS7QAAAAASUVORK5CYII=");
}

.order_page .modal .modal-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.order_page .modal .modal-subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .order_page .modal .modal-body {
        padding: 40px;
    }

    .order_page .modal .modal-close {
        top: 15px;
        right: 15px;
    }
}

@media (max-width: 450px) {
    .order_page .modal .modal-window {
        min-width: unset;
    }

    .order_page .modal .modal-body {
        padding: 40px 15px;
    }

    .order_page .modal .modal-body button[type="submit"] {
        font-size: 20px;
        padding: 15px 20px;
        border-radius: 28px;
    }
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:500,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    -webkit-text-size-adjust: none;
    -webkit-appearance: none;
}

html, body.common_page {
    min-width: 330px;
    background: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #000101;
    line-height: 1.2;
}

body {
    overflow-x: hidden;
}

.common_page input, textarea, select, button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #000101;
    -webkit-appearance: none;
    outline: 0;
    margin: 0;
}

.common_page input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #999;
}

.common_page input::-moz-placeholder, textarea::-moz-placeholder {
    color: #999;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
}

.common_page input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
    color: #eee;
}

.common_page input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
    color: #eee;
}

.common_page input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.common_page .site-width {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1240px;
    box-sizing: border-box;
}

.common_page .button {
    font-size: 20px;
    display: inline-block;
    padding: 20px 30px;
    color: #fff;
    border: #4d697e 2px solid;
    background: #4d697e;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}

.common_page .button:hover {
    background: #5681a1;
    border-color: #5681a1;
    color: #fff;
}

.common_page .button:focus {
    background: #f99f1f;
    border-color: #f99f1f;
    color: #fff;
}

.common_page .button[disabled], .button[disabled]:hover {
    background: #fff;
    color: #4d697e;
    cursor: default;
}

.common_page button {
    cursor: pointer;
    outline: 0;
}

.common_page button[type="submit"], button.submit {
    font-size: 24px;
    display: inline-block;
    padding: 15px 30px;
    color: #fff;
    font-weight: 600;
    border: #f99f1e 1px solid;
    background: #f99f1e;
    border-radius: 30px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}

.common_page button[type="submit"]:disabled, button.submit:disabled {
    background: #a3a3a3;
    border-color: #a3a3a3;
    cursor: default;
}

header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
}

header.common_page .site-width {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 10px;
}

header.common_page .left {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

header.common_page .logo {
    display: block;
}

header.common_page .logo img {
    display: block;
    width: 98px;
    height: 36px;
}

header.common_page .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

header.common_page .nav-opener {
    display: none;
    margin: 0 15px;
    width: 20px;
    height: 10px;
    position: relative;
    border-top: #232323 2px solid;
    border-bottom: #232323 2px solid;
    box-sizing: border-box;
}

header.common_page .nav-active {
}

header.common_page nav {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-height: 32px;
}

header.common_page .lang + nav {
    border-left: #515151 1px solid;
    margin-left: 15px;
    padding-left: 15px;
}

header.common_page nav a, header nav span {
    color: #000101;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

header.common_page nav a:hover {
    text-decoration: underline;
}

header.common_page nav .item {
    margin: 0 15px;
}

header.common_page nav .item a {
    display: flex;
    align-items: center;
}

header.common_page nav .item .item-login:after, header nav .item .item-logout:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    content: '';
}

header.common_page nav .item .item-login:after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC8UlEQVRIib2WXWiOYRjHf+/TDmRmaT4jYSvLx1ZEL1uIWlk5MEucSMPkBMm3Ew4kR8iJhgM5YNIcqBEitdmyWphmEQ44UDP1GrGo6er5P553z32/7xz519tb13Xd/+u+r88nVV9fTx6kgGVAHVANlAPFMs8AfUAb0AJ0AMO5qAJHEqMW6BZRGrgLNACr9GuQLC2bbp3xosAjLAKagI3AZb3mvWMVwl5yHJgNHAFuAzeARmAw2zDpaCpwDyjUTbscahgHzAeWA5+BW7qIkV8ErgPtQA3wyRe6IjkZ8jipBB4CP3XTTmAncAboAabLrktnh8RV5HPUpJdYnPuz5JOBR8B3hXGJZFYYs4AB4HyWfb84CsU5InS1ykk64cSwRRW2Hvid0H0DDqsoxgNfs5xt0suvAq2BSvikEu/LyVLgvsdJhHZxLEzIu8Rp3KkC9UmlwuJDCdDrkUf4obyVOBo4Bbw1H4EcPMlTwlYAYx2pi5QjCTmNuy5Qxz9wTGJ8ASY60hgp5SfjaEIYd3Wg6nnhqGNY2BY50hhz5OyDowlh3OWBZteAo45hpV0hQh9qVGXvPDrEXZxv1kXo1Kv2OJqwPfYBV/INVNSwmVFyYDgB7AJmJuTbNbZOOydiGHcm0KivcNQjcRN4A+xIyPcD5zTzcsG4+wKN+DU5jCIMy25eQl6qHOaDcbcFGvVVQFke4xnAWuBZQm47aC8wxjkRokzcLYE2oxEccsxgikbIK+AjcDah3wos1gTf4JwOOY27I1BYjgHbNFRRNR3V+LDheABYmVxmcmAz7o72kP1Pki4tTuMejsq7FWgGrmmpWdwPqnTnAheAX859Q1if7Na8tL30FFghrmZxj9hH0fp9qS1aqX2Sa2on0aut+xp4LK7GyCbb0aC63MIxAZjmUI2OBYpAj7gGfY7Qjq9SgbTrG6D0HxyYzSWdsbPG8fd7weD7CrJbbNZmtIqzRjUCm8IW1qg5reOtGVeL+DmwLspJEv/nAxL4A/TAsmOMOcA8AAAAAElFTkSuQmCC");
}

header.common_page nav .item .item-logout:after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC70lEQVRIiZ2WzUsWURTGf3e071KohLJVYCEFGkRgakEULV5auQhdDRH4L5i1TVy1aRFmCA0USQtrES1CwshUaFFJlJs+IIJAJcwK+rxx5p7Xd2bujGUPzOace59zzz33PGeMtZZCRMYAB4EOoB1oBGp1+QIwA4wDI8AkYTFZcaDIlIA+oBmYAEaBaWBeV2wBmoBjQCvwDDhHaO96XLmBIrMJGAROAkNAP6F94+1M79kJ9AKngZtAN6FdLA4UmW3APWAD0EloH+eQbgT2ahZzwC1C+1l9B4Bh4AtwnNB+8AO5TB4B34ASoZ1NkMv1XdR6rVKrkBu9ylZC+17X1gFyfWuAtnJmQeKsg5pJNohkOQbsAqoS6yWz9UANMLBkdXtLyjVYNgdKVtKadKaCOJzSANszB0Mzqo2JI1OTCdYZczpuAn3CfXHh82oCR4B1njUdTL59KavjGoq5I2Oq9d6btVfysAOozrEn8VszzqIfeCUxAg0wscwT/gEUNFsKqz2L45Qe7Ai040e9RRXM6YmXg9TwU4FfuNsDlZVpz13BE+CnZ/XxyrM4CHdjoK9m3nNXUO6JIljtqRcFfuGuzT7XPDwAXgPfc3xotsOEdtnrDVSFt3qeMhzB+YQiJGG1fmc9TwXCvRCo1Dd57jSu6YGyELm6lNPkSQj3TKDz5KjnTqNKSbNYC9z3rGkI93igQ6uNyDR4S4jlSa6sC6jzfO7aBojMIc/j9jbE3DASxJMRngI9OQvrgesqJXkPJ1DlGCMyl1XOkuhR7kk3Jpzw3VG5n9IszsQT08lPlRciH++Aw4T2LZFpUVU4IVM3OY9uAC2xA64A+zVIXiZFELJZnQRXgSlC20WGpBuQIfUcqNcsVhIEVfGPOr8WldM5Cka5KPHm/wj0VTVvNjvK00TO0aYnMv+ocWWIysvcknaREb4UxM8oichIrS4Au5VEApcfhWz6lVB10UJp/F5CezuPbqU/kHsA+YkRSA1eAg//+gMJ/AGkje+HrRRwEwAAAABJRU5ErkJggg==");
}

header.common_page .lang {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

.common_page .scrolled header {
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    background: #f5f5f5;
}

.common_page .scrolled header .site-width {
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 950px) {
    header.common_page .site-width {
        padding: 10px;
    }

    header.common_page .nav-opener {
        display: block;
    }

    header.common_page .nav-opener + nav {
        display: none;
    }

    header.common_page .nav-active + nav {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        background: #f5f5f5;
    }

    header.common_page .nav-active + nav .item {
        margin: 20px;
    }

    header.common_page .nav-active {
        border: 0;
    }

    header.common_page .nav-active:before, header .nav-active:after {
        display: block;
        position: absolute;
        top: calc(50% - 1px);
        width: 20px;
        height: 2px;
        background: #232323;
        content: '';
    }

    header.common_page .nav-active:before {
        transform: rotateZ(45deg);
    }

    header.common_page .nav-active:after {
        transform: rotateZ(-45deg);
    }
}

@media (max-width: 450px) {
    header.common_page .left {
        margin-right: 0;
    }

    header.common_page .lang {
        margin: 0;
    }

    header.common_page .lang + nav {
        margin-left: 15px;
        padding-left: 0;
    }
}

.common_page .top-block {
    margin-bottom: 25px;
    margin-top: 86px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.common_page .top-block .slogan {
    background: #f99f1e;
    border-radius: 20px;
    color: #fff;
    padding: 60px 40px 60px 50px;
    position: relative;
}

.common_page .top-block .slogan-title {
    font-size: 42px;
    font-weight: bold;
}

.common_page .top-block .slogan-title i {
    font-style: normal;
    color: #f99f1e;
    position: relative;
    display: inline-block;
    padding: 0 10px;
}

.common_page .top-block .slogan-title i span {
    position: relative;
}

.common_page .top-block .slogan-title i:before {
    background: #fff;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    transform: skewX(-10deg);
    content: '';
}

.common_page .top-block .slogan-more {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    position: absolute;
    bottom: 35px;
    right: 40px;
    padding: 0 15px 10px 0;
}

.common_page .top-block .slogan-more:before {
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 20px;
    background: #fff;
    content: '';
}

.common_page .top-block .slogan-more:after {
    display: block;
    width: 14px;
    height: 13px;
    position: absolute;
    bottom: -5.5px;
    right: 0;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAA5UlEQVQokZWSvU5CQRCFP3+idlhIYeUrEBuIT8AD+Cb2NrQ0PIONNhbEB9CGzpYKCkJBjCZqhMQYwmdW9+rm3guBk8xmMyczZ/bsoOajpi7Udgn3F4WE2vQXc/WkhP+JbYoYx8wOcFFgM6jX6lnSreI/pupRmWI43tS+upcQH0nxZVnhlvoInAI94AWoAA1gPw41AwbJkAvgNlR33Ryfu8Bh7DQCnqNCHTiI+XfgPlF8Be6C4lB9iqZkb5gkM7SWmfOgnueI8IcBX+rxugsQ7M9wtckCVJN7Z9UC5KMaXFNvlu6q8g3x0rMymdsOMwAAAABJRU5ErkJggg==");
}

.common_page .top-block .steps {
    padding: 30px 30px 25px;
    display: flex;
    justify-content: space-between;
}

.common_page .top-block .step {
    display: flex;
    flex-direction: column;
}

.common_page .top-block .step-img {
    flex-shrink: 0;
    height: 125px;
    margin-bottom: 10px;
}

.common_page .top-block .step-img a {
    display: block;
}

.common_page .top-block .step-img img {
    height: 125px;
    width: 200px;
    display: block;
    margin: 0 auto;
}

.common_page .top-block .step-text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.common_page .top-block .step-num {
    margin-right: 15px;
    font-size: 34px;
    color: #f99f1e;
    font-weight: bold;
}

@media (max-width: 950px) {
    .common_page .top-block {
        margin-top: 56px;
    }

    .common_page .top-block .slogan {
        padding: 20px;
    }

    .common_page .top-block .slogan-title {
        font-size: 30px;
    }

    .common_page .top-block .slogan-more {
        display: none;
    }

    .common_page .top-block .steps {
        padding: 20px 10px;
    }

    .common_page .top-block .step {
        margin: 0 10px;
    }

    .common_page .top-block .step-img {
        display: none;
    }

    .common_page .top-block .step-text {
        width: auto;
    }

    .common_page .top-block .step-num {
        margin-right: 5px;
    }
}

@media (max-width: 600px) {
    .common_page .top-block .slogan-title {
        font-size: 20px;
    }

    .common_page .top-block .steps {
        flex-wrap: wrap;
    }

    .common_page .top-block .step {
        width: calc(50% - 20px);
    }

    .common_page .top-block .step-text {
        justify-content: flex-start;
    }

    .common_page .top-block .step-text br {
        display: none;
    }
}

@media (max-width: 450px) {
    .common_page .top-block .step {
        width: calc(100% - 20px);
    }
}

.common_page .search-block {
    display: flex;
}

.common_page .search-block .news-block {
    width: 200px;
    margin-left: 25px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.common_page .search-block .form-block {
    flex-grow: 1;
    width: calc(100% - 225px);
}

.common_page .news-block {
    padding: 20px 12px 15px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.common_page .news-block .title {
    font-size: 18px;
    text-align: center;
}

.common_page .news-block .item {
    margin-top: 40px;
    color: #000101;
    text-decoration: none;
    font-size: 11px;
    display: block;
    line-height: 1.5;
}

.common_page .news-block .item-more {
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    margin-top: 15px;
    padding-bottom: 4px;
    border-bottom: #a3a3a3 1px solid;
}

@media (max-width: 1200px) {
    .common_page .news-block {
        display: none;
    }
}

.common_page .form-block {
    display: flex;
    flex-direction: column;
}

.common_page .form-block .tabs-head {
    display: flex;
}

.common_page .form-block .tabs-head .tab {
    flex-grow: 1;
    position: relative;
    margin: 0 8px;
    padding: 20px;
    background: #fcfcfc;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    border-radius: 20px 20px 0 0;
}

.common_page .form-block .tabs-head .tab:first-child {
    margin-left: 0;
}

.common_page .form-block .tabs-head .tab:last-child {
    margin-right: 0;
}

.common_page .form-block .tabs-head .tab.tab-active {
    background: #fff;
    z-index: 2;
}

.common_page .form-block .tabs-body {
    flex-grow: 1;
    padding: 30px 40px;
    border-radius: 0 0 20px 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    position: relative;
    z-index: 1;
}

.common_page .form-block .tabs-body .tab {
    display: none;
}

.common_page .form-block .tabs-body .tab.tab-active {
    display: block;
}

.common_page .form-block .filter label {
    font-size: 16px;
    display: block;
}

.common_page .form-block .filter .filter-control label {
    height: 19px;
    z-index: 1;
    position: relative;
}

.common_page .form-block .filter .filter-airports {
    display: flex;
    margin-bottom: 35px;
    position: relative;
}

.common_page .form-block .filter .filter-airport {
    width: 33%;
    position: relative;
}

.common_page .form-block .filter .filter-airport label {
    margin-bottom: 20px;
}

.common_page .form-block .filter .filter-airport-to {
    text-align: right;
}

.common_page .form-block .filter .filter-airport-name {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.common_page .form-block .filter .filter-airport-code {
    font-size: 18px;
    color: #949494;
}

.common_page .form-block .filter .filter-airport-hint {
    position: absolute;
    top: 30px;
    left: 0;
    display: none;
    min-width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    box-sizing: border-box;
    z-index: 2;
}

.common_page .form-block .filter .filter-airport-name:hover ~ .filter-airport-hint, .form-block .filter .filter-airport-hint:hover {
    display: block;
}

.common_page .form-block .filter .filter-airport-none {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 5px;
}

.common_page .form-block .filter .filter-airport-none .icon {
    display: block;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f5f5f5;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.common_page .form-block .filter .filter-airport-none .icon-question {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAYAAAA2yOUNAAAAzUlEQVQokX3PsUqCYRjF8Z8fQQQNIQVFga4tQjeR4FLEtzoYRBfRoDU31Bw0BE0NXoJCDtIQQkvS3lxQQ7jES28pbx+e8Zw/zzlPKc9zWEMHe9jEF+5xisEC1jGM4S0esYUj9NAIUBsVHKBrqiuMcJ5hH08JEPSCPmpZ7B8q1gbeQl21MOYQO7jM/kU/asVNDzgpgpq4jhN28RnqZrWIC4xRx0cw00vbKOPuFyiClvGO11kzrRtgJR2ZXlqKw1fnQce4wdk8KHw1wfOfg2+SySWHhi2aPwAAAABJRU5ErkJggg==");
}

.common_page .form-block .filter .filter-airport-none-link {
    font-size: 11px;
    font-weight: 400;
    color: #000101;
}

.common_page .form-block .filter .filter-airport-none-hint {
    position: absolute;
    top: 0px;
    left: 0;
    display: none;
    padding: 10px;
    border-radius: 20px;
    text-align: left;
    font-size: 16px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    box-sizing: border-box;
    z-index: 2;
}

.common_page .form-block .filter .filter-airport-none-hint a {
    color: #000101;
}

.common_page .form-block .filter .filter-airport-none:hover .filter-airport-none-hint {
    display: block;
}

.common_page .form-block .filter .filter-track {
    flex-grow: 1;
    position: relative;
    margin: 50px 15px 0;
    height: 1px;
    background: #a3a3a3;
}

.common_page .form-block .filter .filter-track:before, .form-block .filter .filter-track:after {
    position: absolute;
    top: -4.5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #a3a3a3;
    content: '';
}

.common_page .form-block .filter .filter-track:before {
    left: 0;
}

.common_page .form-block .filter .filter-track:after {
    right: 0;
}

.common_page .form-block .filter .filter-track i {
    display: block;
    width: 50px;
    height: 34px;
    margin: -16.5px auto 0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAYAAADYmxC7AAACPElEQVRYhc2XO2gUQRyHP88zPtAQjAYFFe1MkVrUQsFCSGEgNoLYaaP4KhQDgim0FJ+FGAQ7C6OVdhYSQbG0EcFHoaIgik8sBI382Qk3t7/Zu525Y5MPDm6//c/yu5m5mdl50zeJpQ+YAoaAH8A14BQwHf2kAmph3ZJRF8joBU66UF0jJdR6MXAaWCE2kZRQ88XAEuCw2ApDvROTcQhYKjaBbobqBw6IrSjUezENjgM9YisI9VZMg7XAXrEJodYBe4AdcjfMV+B38E7GicQf2xTqGXALeAAckYowRfPKGAR2iY0MVfeuz7kh6CQUnS6mFuqVd21/6QtSpbSa7MYmYJvYiFBvcm43MCyVzbQLZYyJKYkN3etA6VVgC7BI7sCykhN5J7Af+Oy5hcBiqcywznlkG3s9N3wzbAA+io1nIrLFE2CkVtBTs8Vm4MZcC2UMW6hfomeXLxZq9RwLNW4TfY3oarEj9T/gBXAemKy7vS/PX+Ag8Mnzdgb/7r5vB85IK2Ur8Nyztmf+kaocRT11CbgutsFGMcpD4LHYEtQCoT7YuLZpOiBGKbNdBQmFOgb8DBV7rBLTzEvgntiIUP3etR1fbkuV0u7N5bKbvEnYnDoL7HONj5Z8SKue+gbEv+J6WKi77hNDqzk10emCnHpsXSkmw5aSK2IjSQm1AFguNmOyxKm0LSmhinqJTpYBn26GsoXyqdiKQhVxscBXEsqOrfk1yHrpTpcyJYWy1d56xTZo+7fddy8byYtlE8B/EeRYOTB09x4AAAAASUVORK5CYII=");
}

.common_page .form-block .filter .filter-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.common_page .form-block .filter .filter-control {
    width: 30%;
}

.common_page .form-block .filter .filter-control label {
    margin-bottom: 5px;
}

.common_page .form-block .filter .filter-control input {
    text-align: center;
    width: 100%;
    display: block;
}

.common_page .form-block .filter input[type="number"]::-webkit-outer-spin-button, .form-block .filter input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.common_page .form-block .filter input[type="number"] {
    -moz-appearance: textfield;
    cursor: text;
    width: 100%;
}

.common_page .form-block .filter input[type="number"]:hover, .form-block .filter input[type="number"]:focus {
    -moz-appearance: number-input;
}

.common_page .form-block .filter .filter-checkbox {
    margin-bottom: 35px;
}

.common_page .form-block .filter .filter-bottom {
    display: flex;
    justify-content: space-between;
}

.common_page .form-block .filter .filter-links > div {
    display: flex;
    align-items: center;
}

.common_page .form-block .filter .filter-links > div + div {
    margin-top: 10px;
}

.common_page .form-block .filter .filter-links a {
    font-size: 11px;
    font-weight: 400;
    color: #000101;
    text-decoration: none;
}

.common_page .form-block .filter .filter-links a:hover {
    text-decoration: underline;
}

.common_page .form-block .filter .filter-links .icon {
    display: block;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f5f5f5;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.common_page .form-block .filter .filter-links .icon-rules {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAYAAAA2yOUNAAAAzUlEQVQokX3PsUqCYRjF8Z8fQQQNIQVFga4tQjeR4FLEtzoYRBfRoDU31Bw0BE0NXoJCDtIQQkvS3lxQQ7jES28pbx+e8Zw/zzlPKc9zWEMHe9jEF+5xisEC1jGM4S0esYUj9NAIUBsVHKBrqiuMcJ5hH08JEPSCPmpZ7B8q1gbeQl21MOYQO7jM/kU/asVNDzgpgpq4jhN28RnqZrWIC4xRx0cw00vbKOPuFyiClvGO11kzrRtgJR2ZXlqKw1fnQce4wdk8KHw1wfOfg2+SySWHhi2aPwAAAABJRU5ErkJggg==");
}

.common_page .form-block .filter .filter-links .icon-oferta {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==");
}

.common_page .form-block .filter button[type="submit"] {
    min-width: 250px;
}

.common_page .form-block .filter #return-flight {
    display: none;
}

.common_page .form-block .filter #return-flight.return-flight-active {
    display: flex;
}

@media (max-width: 900px) {
    .common_page .form-block .tabs-head .tab {
        padding: 10px;
    }

    .common_page .form-block .tabs-body {
        padding: 30px;
    }

    .common_page .form-block .filter .filter-airports, .form-block .filter #return-flight.return-flight-active {
        display: block;
    }

    .common_page .form-block .filter .filter-airport {
        width: auto;
    }

    .common_page .form-block .filter .filter-airport label {
        margin-bottom: 10px;
    }

    .common_page .form-block .filter .filter-airport label:after {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        width: 32px;
        height: 22px;
        content: '';
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAYAAADYmxC7AAACPElEQVRYhc2XO2gUQRyHP88zPtAQjAYFFe1MkVrUQsFCSGEgNoLYaaP4KhQDgim0FJ+FGAQ7C6OVdhYSQbG0EcFHoaIgik8sBI382Qk3t7/Zu525Y5MPDm6//c/yu5m5mdl50zeJpQ+YAoaAH8A14BQwHf2kAmph3ZJRF8joBU66UF0jJdR6MXAaWCE2kZRQ88XAEuCw2ApDvROTcQhYKjaBbobqBw6IrSjUezENjgM9YisI9VZMg7XAXrEJodYBe4AdcjfMV+B38E7GicQf2xTqGXALeAAckYowRfPKGAR2iY0MVfeuz7kh6CQUnS6mFuqVd21/6QtSpbSa7MYmYJvYiFBvcm43MCyVzbQLZYyJKYkN3etA6VVgC7BI7sCykhN5J7Af+Oy5hcBiqcywznlkG3s9N3wzbAA+io1nIrLFE2CkVtBTs8Vm4MZcC2UMW6hfomeXLxZq9RwLNW4TfY3oarEj9T/gBXAemKy7vS/PX+Ag8Mnzdgb/7r5vB85IK2Ur8Nyztmf+kaocRT11CbgutsFGMcpD4LHYEtQCoT7YuLZpOiBGKbNdBQmFOgb8DBV7rBLTzEvgntiIUP3etR1fbkuV0u7N5bKbvEnYnDoL7HONj5Z8SKue+gbEv+J6WKi77hNDqzk10emCnHpsXSkmw5aSK2IjSQm1AFguNmOyxKm0LSmhinqJTpYBn26GsoXyqdiKQhVxscBXEsqOrfk1yHrpTpcyJYWy1d56xTZo+7fddy8byYtlE8B/EeRYOTB09x4AAAAASUVORK5CYII=");
    }

    .common_page .form-block .filter .filter-airport-to {
        text-align: left;
        margin-top: 30px;
    }

    .common_page .form-block .filter .filter-track {
        height: 124px;
        width: 1px;
        position: absolute;
        top: 43px;
        left: -15px;
        margin: 0;
    }

    .common_page .form-block .filter .filter-track:before {
        left: -4.5px;
        top: 0;
    }

    .common_page .form-block .filter .filter-track:after {
        left: -4.5px;
        right: auto;
        bottom: 0;
        top: auto;
    }

    .common_page .form-block .filter .filter-track i {
        display: none;
    }

    .common_page .form-block .filter .filter-controls {
        display: block;
    }

    .common_page .form-block .filter .filter-control {
        width: 100%;
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
    }

    .common_page .form-block .filter .filter-control label {
        border-bottom: #a3a3a3 1px solid;
        margin-bottom: 0;
        padding-bottom: 5px;
        flex-grow: 1;
        height: auto;
    }

    .common_page .form-block .filter .filter-control input {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .common_page .form-block .filter .filter-control .control {
        max-width: 250px;
    }
}

@media (max-width: 600px) {
    .common_page .form-block .filter .filter-bottom {
        display: block;
    }

    .common_page .form-block .filter .filter-submit {
        margin-bottom: 20px;
        text-align: center;
    }

    .common_page .form-block .filter .filter-links {
        display: flex;
        flex-wrap: wrap;
    }

    .common_page .form-block .filter .filter-links > div {
        margin-right: 20px;
    }

    .common_page .form-block .filter .filter-links > div + div {
        margin-top: 0;
    }
}

@media (max-width: 400px) {
    .common_page .form-block .filter .filter-control {
        display: block;
    }

    .common_page .form-block .filter .filter-control label {
        border-bottom: 0;
    }
}

.common_page .attention-block {
    font-size: 11px;
    margin: 10px 0 10px 20px;
}

.common_page .sort-block {
    display: flex;
    align-items: center;
    padding: 25px 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.common_page .sort-block .title {
    font-size: 16px;
    margin-right: 30px;
    white-space: nowrap;
}

.common_page .sort-block .control-button {
    min-width: 200px;
}

@media (max-width: 850px) {
    .common_page .sort-block .control-button {
        min-width: 0;
    }
}

@media (max-width: 700px) {
    .common_page .sort-block {
        display: block;
        padding: 20px;
    }

    .common_page .sort-block .title {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 400px) {
    .common_page .sort-block .control-button {
        font-size: 13px;
        padding: 5px 10px;
    }
}

.common_page .flights-block {
    margin-bottom: 30px;
}

.common_page .flights-block .flights-head {
}

.common_page .flights-block .flights-head .flight-table {
    width: calc(100% - 110px);
    padding: 5px 20px;
    box-sizing: border-box;
}

.common_page .flights-block .flights-body {
    display: flex;
    border-radius: 20px;
    background: #d4d4d4;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    cursor: pointer;
}

.common_page .flights-block .flights-body .flight-table {
    width: calc(100% - 110px);
    padding: 5px 20px;
    border-radius: 20px;
    background: #fff;
    box-sizing: border-box;
}

.common_page .flights-block .flights-body .flight-select {
    width: 110px;
    padding: 15px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
}

.common_page .flights-block .flights-body.flights-active {
    background: #f99f1e;
}

.common_page .flights-block .flights-summary {
    padding: 15px;
    padding-right: 150px;
    font-size: 14px;
    text-align: right;
}

.common_page .flights-block table {
    border-collapse: collapse;
    width: 100%;
}

.common_page .flights-block table tr:last-child td {
    border-bottom: 0;
}

.common_page .flights-block table th {
    text-align: center;
    vertical-align: top;
    font-size: 10px;
    font-weight: 500;
    padding: 15px 10px;
}

.common_page .flights-block table td {
    border-bottom: #c5c5c5 1px solid;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    padding: 15px 10px;
}

.common_page .flights-block table td small {
    font-size: 12px;
}

.common_page .flights-block table .flight-plane-info {
    display: inline-flex;
    align-items: center;
}

.common_page .flights-block table .flight-plane-name {
    text-align: left;
    width: 100px;
    overflow: hidden;
}

.common_page .flights-block table .flight-plane-img {
    width: 60px;
    margin-left: 10px;
    text-align: center;
}

.common_page .flights-block table .flight-plane-img small {
    font-size: 10px;
}

.common_page .flights-block table .flight-duration small {
    font-size: 10px;
}

.common_page .flights-block table .flight-plane-img img {
    display: block;
    width: 100%;
}

.common_page .flights-block table .flight-logo img {
    max-width: 100%;
}

.common_page .flights-block table .flight-plane {
    width: 170px;
}

.common_page .flights-block table .flight-logo {
    width: 90px;
}

.common_page .flights-block table .flight-airport {
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.common_page .flights-block table .flight-airport div {
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
    margin-right: auto;
}

.common_page .flights-block table .flight-date {
    width: 120px;
}

.common_page .flights-block table .flight-duration {
    width: 80px;
}

.common_page .flights-block table .flight-price {
    width: 100px;
    white-space: nowrap;
}

.common_page .flights-block table th.flight-price {
    padding-top: 3px;
    vertical-align: middle;
}

.common_page .flights-block table .flight-price .control {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

.common_page .flights-block table .flight-price .control i {
    display: inline-block;
    width: 14px;
    height: 19px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.common_page .flights-block table .flight-price .control i[data-currency="rub"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.93 25.48'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23494949;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EР РµСЃСѓСЂСЃ 4%3C/title%3E%3Cg id='РЎР»РѕР№_2' data-name='РЎР»РѕР№ 2'%3E%3Cg id='Layer_1' data-name='Layer 1'%3E%3Crect class='cls-1' y='16.88' width='14.26' height='2.48'/%3E%3Cpath class='cls-1' d='M9.62,14.61H0V12.13H9.62a4.83,4.83,0,1,0,0-9.65H3.76V0H9.62a7.31,7.31,0,1,1,0,14.61Z'/%3E%3Crect class='cls-1' x='2.52' y='0.05' width='2.48' height='25.43'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.common_page .flights-block table .flight-price .control i[data-currency="usd"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.26 28.7'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23494949;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EР РµСЃСѓСЂСЃ 2%3C/title%3E%3Cg id='РЎР»РѕР№_2' data-name='РЎР»РѕР№ 2'%3E%3Cg id='Layer_1' data-name='Layer 1'%3E%3Crect class='cls-1' x='6.39' width='2.48' height='28.7'/%3E%3Cpath class='cls-1' d='M7.7,27l-.63,0C1.63,26.67.2,22.6.2,20.5H2.68c0,.39.18,3.77,4.54,4a5.4,5.4,0,0,0,5-2A3.34,3.34,0,0,0,12.37,19a5.67,5.67,0,0,0-2.71-2.22l-.25-.14L7.07,15.45h0L4.69,14.21l-.25-.13a8,8,0,0,1-3.76-3.3A5.77,5.77,0,0,1,1,4.86,7.85,7.85,0,0,1,8.19,1.7c5.45.33,6.87,4.39,6.87,6.5H12.58c0-.37-.17-3.75-4.54-4A5.42,5.42,0,0,0,3,6.22a3.34,3.34,0,0,0-.15,3.44,5.76,5.76,0,0,0,2.7,2.22l.26.14L8.2,13.25h0l2.37,1.24.24.13a7.92,7.92,0,0,1,3.77,3.3,5.77,5.77,0,0,1-.29,5.92A7.67,7.67,0,0,1,7.7,27Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.common_page .flights-block table .flight-price .control i[data-currency="eur"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.9 26.58'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23494949;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EР РµСЃСѓСЂСЃ 3%3C/title%3E%3Cg id='РЎР»РѕР№_2' data-name='РЎР»РѕР№ 2'%3E%3Cg id='Layer_1' data-name='Layer 1'%3E%3Crect class='cls-1' y='9.83' width='15.78' height='2.48'/%3E%3Crect class='cls-1' y='14.59' width='15.78' height='2.48'/%3E%3Cpath class='cls-1' d='M14.68,26.58a10.76,10.76,0,0,1-8.23-4,14.35,14.35,0,0,1-3.31-9.33A14.37,14.37,0,0,1,6.45,4a10.8,10.8,0,0,1,8.23-4A10.76,10.76,0,0,1,22.9,4L21,5.57a8.35,8.35,0,0,0-6.34-3.09A8.33,8.33,0,0,0,8.34,5.57a11.84,11.84,0,0,0-2.72,7.72A11.8,11.8,0,0,0,8.34,21a8.31,8.31,0,0,0,6.34,3.1A8.33,8.33,0,0,0,21,21l1.88,1.61A10.73,10.73,0,0,1,14.68,26.58Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

@media (max-width: 1200px) {
    .common_page .flights-block.flights-block-lg {
        display: none;
    }
}

.common_page .flights-block.flights-block-md,
.common_page .flights-block.flights-block-sm {
    display: none;
}

.common_page .flights-block.flights-block-md .flight-item,
.common_page .flights-block.flights-block-sm .flight-item {
    padding: 10px 30px;
    margin-bottom: 5px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.common_page .flights-block.flights-block-md .flight-title,
.common_page .flights-block.flights-block-sm .flight-title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
}

.common_page .flights-block.flights-block-md .flight-title:before,
.common_page .flights-block.flights-block-sm .flight-title:before {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    width: 32px;
    height: 22px;
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAYAAADYmxC7AAACPElEQVRYhc2XO2gUQRyHP88zPtAQjAYFFe1MkVrUQsFCSGEgNoLYaaP4KhQDgim0FJ+FGAQ7C6OVdhYSQbG0EcFHoaIgik8sBI382Qk3t7/Zu525Y5MPDm6//c/yu5m5mdl50zeJpQ+YAoaAH8A14BQwHf2kAmph3ZJRF8joBU66UF0jJdR6MXAaWCE2kZRQ88XAEuCw2ApDvROTcQhYKjaBbobqBw6IrSjUezENjgM9YisI9VZMg7XAXrEJodYBe4AdcjfMV+B38E7GicQf2xTqGXALeAAckYowRfPKGAR2iY0MVfeuz7kh6CQUnS6mFuqVd21/6QtSpbSa7MYmYJvYiFBvcm43MCyVzbQLZYyJKYkN3etA6VVgC7BI7sCykhN5J7Af+Oy5hcBiqcywznlkG3s9N3wzbAA+io1nIrLFE2CkVtBTs8Vm4MZcC2UMW6hfomeXLxZq9RwLNW4TfY3oarEj9T/gBXAemKy7vS/PX+Ag8Mnzdgb/7r5vB85IK2Ur8Nyztmf+kaocRT11CbgutsFGMcpD4LHYEtQCoT7YuLZpOiBGKbNdBQmFOgb8DBV7rBLTzEvgntiIUP3etR1fbkuV0u7N5bKbvEnYnDoL7HONj5Z8SKue+gbEv+J6WKi77hNDqzk10emCnHpsXSkmw5aSK2IjSQm1AFguNmOyxKm0LSmhinqJTpYBn26GsoXyqdiKQhVxscBXEsqOrfk1yHrpTpcyJYWy1d56xTZo+7fddy8byYtlE8B/EeRYOTB09x4AAAAASUVORK5CYII=");
}

.common_page .flights-block.flights-block-md .flight-item + .flight-item .flight-title:before,
.common_page .flights-block.flights-block-sm .flight-item + .flight-item .flight-title:before {
    transform: rotateZ(180deg);
}

.common_page .flights-block.flights-block-md table th,
.common_page .flights-block.flights-block-sm table th {
    border-bottom: #d3d3d3 1px solid;
    vertical-align: middle;
}

.common_page .flights-block.flights-block-md table th.flight-price,
.common_page .flights-block.flights-block-sm table th.flight-price {
    padding-top: 5px;
    padding-bottom: 5px;
}

.common_page .flights-block.flights-block-md table .flight-airport, .flights-block.flights-block-md table .flight-airport div,
.common_page .flights-block.flights-block-sm table .flight-airport, .flights-block.flights-block-sm table .flight-airport div {
    width: 250px;
}

.common_page .flights-block.flights-block-md .flight-summary,
.common_page .flights-block.flights-block-sm .flight-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    margin: 20px 30px;
    white-space: nowrap;
}

.common_page .flights-block.flights-block-md .flight-summary button.submit,
.common_page .flights-block.flights-block-sm .flight-summary button.submit {
    order: 0;
    background: #a3a3a3;
    border-color: #a3a3a3;
}

.common_page .flights-block.flights-block-md .flight-summary div,
.common_page .flights-block.flights-block-sm .flight-summary div {
    order: 1;
}

.common_page .flights-block.flights-block-md button.submit.active,
.common_page .flights-block.flights-block-sm button.submit.active {
    background: #f99f1e;
    border-color: #f99f1e;
}

@media (max-width: 1200px) {
    .common_page .flights-block.flights-block-md {
        display: block;
    }
}

@media (max-width: 800px) {
    .common_page .flights-block.flights-block-md table .flight-airport, .flights-block.flights-block-md table .flight-airport div {
        width: 200px;
    }
}

@media (max-width: 700px) {
    .common_page .flights-block.flights-block-md table .flight-airport, .flights-block.flights-block-md table .flight-airport div {
        width: 120px;
    }
}

@media (max-width: 600px) {
    .common_page .flights-block.flights-block-md {
        display: none;
    }

    .common_page .flights-block.flights-block-sm {
        display: block;
    }

    .common_page .flights-block.flights-block-sm table th:first-child, .flights-block.flights-block-sm table td:first-child {
        width: 50%;
        padding-left: 0;
    }

    .common_page .flights-block.flights-block-sm table th:last-child, .flights-block.flights-block-sm table td:last-child {
        width: 50%;
        padding-right: 0;
    }

    .common_page .flights-block.flights-block-sm table .flight-airport div {
        width: auto;
        white-space: normal;
    }

    .common_page .flights-block.flights-block-sm .flight-summary {
        display: block;
        text-align: center;
    }

    .common_page .flights-block.flights-block-sm .flight-summary div {
        margin-bottom: 15px;
        text-align: left;
    }
}

@media (max-width: 400px) {
    .common_page .flights-block.flights-block-sm table .flight-airport div {
        max-width: 150px;
    }
}

.common_page .share-block {
    margin: 30px;
}

.common_page .share-block a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #000101;
}

.common_page .share-block a:hover {
    text-decoration: underline;
}

.common_page .share-block a:before {
    flex-shrink: 0;
    margin-right: 10px;
    width: 24px;
    height: 26px;
    display: block;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAaCAYAAACtv5zzAAABgUlEQVRIibXWwUtUURTH8Y9agpBkrURECnQjgX9Bf0DUX5AuXEcboZWbclWu/AOildAikRbtok3rdi2i0IWuTBc1mZKEWRw4wmteMvnmzhced85wz2/m3Pe791wFmcZbnKCFxxgoJT+Cz/iNj/ian5drMxsyn4JPM300q2j1F/qBSzl+ynEP3zBcm3lOhrCI/awgRB/hZcavmwr3YQ7bKXSA5zjOOJ4NXK9l/gc38S5FwjHPMJZpE7iLWxg8r/AU1iv/8A1marMacBUr+JnCH3C7idA4buBixlHiAr6kcDjjHi7UMjtwGa8qpe+kMzYz/oEnOa8Rqyn0HmttTghnXOt2jY+wm54OllJ8sTazAac7uS8flbEYqxV3xA78VXqJznrJGxkfdfuST+mZTTtx5R8b7U6HnEZM9uqoaKdnh12VsPJs5bg+LHlcV2lvONEmH+JFtw2nnfsp+CC/jwq3Yk+V6snR0YK4ugTR9MN93wvp/3VtibWP3lz02hLUL14M/AHNFHsGsLHuDAAAAABJRU5ErkJggg==");
}

@media (max-width: 400px) {
    .common_page .share-block a {
        font-size: 14px;
    }
}


.common_page .loading {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.common_page .loading.loading-active {
    display: flex;
}

.common_page .loading:before {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    content: '';
}

.common_page .loading .loading-window {
    position: fixed;
    max-width: 1000px;
    width: 90%;
    max-height: 90%;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.common_page .loading .loading-body {
    background: #fff;
    border-radius: 20px;
    padding: 20px 60px;
    overflow: auto;
    box-sizing: border-box;
    position: relative;
}

.common_page .loading .loading-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.common_page .loading .loading-progress {
    height: 20px;
    border-radius: 10px;
    background: #f3f3f3;
    overflow: hidden;
}

.common_page .loading .loading-progress-bar {
    background: #ffba20;
    width: 0;
    height: 100%;
}

@media (max-width: 800px) {
    .common_page .loading .loading-body {
        padding: 20px;
    }
}

.common_page .modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    align-items: center;
    justify-content: center;
}

.common_page .modal.modal-active {
    display: flex;
}

.common_page .modal:before {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    content: '';
}

.common_page .modal .modal-window {
    border-radius: 20px;
    position: relative;
    max-width: 95%;
    max-height: 90%;
    min-width: 400px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    overflow: auto;
}

.common_page .modal .modal-body {
    background: #fff;
    border-radius: 20px;
    padding: 40px 100px;
    box-sizing: border-box;
    position: relative;
    font-size: 13px;
}

.common_page .modal .modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 21px;
    height: 21px;
    z-index: 1;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAB2UlEQVQ4ja3VTYjNYRTH8c9cTCiyUDIbZSVZ2SgpSUpThDBS5CVFmY23QnbTKG8r4yWJvJSkKJlQQlazuSvKSrEYWSiljJdm6OTc5vb/32vuNH67+5xzv/c55/zOc9uq1epCXMFWvDcxzcXHCs5hKV5i/gSQa/AOewO6BQOYh1dYUEofWxtxD1OxKKBfsCqBHXgRgXEAo2130I6z6K5k4CtW4xnm4DkWl75e1m7cwCScxCH8rtSlfcu+9GN2/sCSEmZU+3LAATyBY7VIpZA4hPV4gFl4imUlHAfQhzYcQU99sAgN/cRm3MVMPMHKuvjR7F2oG6eLgMkl5F/9ygF8x3Y8xIa0XpQ6EtbJ8ktqBg0NYyd+YA8eZWVxvisH1FCNyq9X7Uav63Iv/guoBWjEL6VvR/Ispr6tlNkiNKxyLUsfSrv15Pn1PG+oZj2dgpvoSv+uTd/25+deXM4t6mvlpu1pp67CptVU25zQeRweCzoN97Gu8CYUFT7dHyuJUzjeDDo9/diJz2n4gRJuVBeyr8PZ694idAYeJ+gTVqBawpR1FTsSHJt2JlY3BhU7HsB4PAYT/LYFYE23cvNu42C8qXHTeAsD+AHLxwmsKR7oTbl9b/73f1QHBv8AmJdqImTRS7QAAAAASUVORK5CYII=");
}

.common_page .modal .modal-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.common_page .modal .modal-subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .common_page .modal .modal-body {
        padding: 40px;
    }

    .common_page .modal .modal-close {
        top: 15px;
        right: 15px;
    }
}

@media (max-width: 450px) {
    .common_page .modal .modal-window {
        min-width: unset;
    }

    .common_page .modal .modal-body {
        padding: 40px 15px;
    }

    .common_page .modal .modal-body button[type="submit"] {
        font-size: 20px;
        padding: 15px 20px;
        border-radius: 28px;
    }
}

.common_page .form {
}

.common_page .form .form-control {
    max-width: 400px;
    margin: 5px auto;
}

.common_page .form .form-control input {
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 15px 5px;
    border: 0;
    border-bottom: #a3a3a3 1px solid;
    box-sizing: border-box;
}

.common_page .form .form-control .form-error {
    margin-top: 5px;
    font-size: 11px;
    color: #ff0000;
}

.common_page .form .form-control-error input {
    border-color: #ff0000;
}

.common_page .form .form-checkbox {
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}

.common_page .form .form-checkbox label {
    font-size: 16px;
}

.common_page .form .form-submit {
    text-align: center;
    margin-top: 30px;
}

.common_page .form .form-submit button {
    min-width: 260px;
}

.common_page .form .form-links {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

.common_page .form .form-link + .form-link {
    margin-top: 30px;
}

.common_page .form .form-link a {
    text-decoration: none;
    font-weight: 600;
}

.common_page .form .form-link a:hover {
    text-decoration: underline;
}

.common_page .form .form-link-password {
    color: #c6c6c6;
}

.common_page .form .form-link-reg {
    color: #f99f1e;
    font-size: 18px;
}

.common_page .form .form-policy label {
    font-size: 11px;
    align-items: flex-start;
}

.common_page .form .form-policy a {
    color: #000101;
    text-decoration: underline;
}

.common_page .form .form-require {
    font-size: 11px;
    margin: 30px auto;
}


.common_page .control-input {
    font-size: 18px;
    padding: 10px 5px;
    background: #fff;
    box-sizing: border-box;
    border: 0;
    border-bottom: #a3a3a3 1px solid;
}

.common_page .control-input-big {
    padding: 15px 5px;
    font-size: 30px;
    font-weight: 500;
}

.common_page .control-button {
    display: inline-block;
    border: #bbb9b9 1px solid;
    background: #f5f5f5;
    padding: 10px 30px;
    margin: 0;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
}

.common_page .control-button:focus {
    background: #d4d3d3;
}

.common_page .control-button-active {
    background: #d4d3d3;
}

.common_page .control-button-big {
    padding: 15px 40px;
    font-size: 18px;
}

@media (max-width: 500px) {
    .common_page .control-button {
        padding: 10px 15px;
    }

    .common_page .control-button-big {
        padding: 10px 20px;
    }
}

.common_page .control {
    position: relative;
}

.common_page .controls {
    display: flex;
}

.common_page .controls > .control-button {
    margin-left: -1px;
    border-radius: 0;
}

.common_page .controls > .control-button:first-child {
    margin-left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.common_page .controls > .control-button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.common_page .controls .control {
    position: relative;
}

.common_page .controls .control + .control {
    margin-left: -1px;
}

.common_page .controls .control .control-button {
    border-radius: 0;
}

.common_page .controls .control:first-child .control-button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.common_page .controls .control:last-child .control-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.common_page .controls .control .control-button-dropdown:focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.common_page .control-datepicker {
    position: relative;
    cursor: pointer;
}

.common_page .control-datepicker:after {
    position: absolute;
    top: 50%;
    right: 25px;
    display: block;
    transform: translateY(-9px) rotateZ(45deg);
    width: 12px;
    height: 12px;
    border-right: #555 2px solid;
    border-bottom: #555 2px solid;
    box-sizing: border-box;
    content: '';
}

@media (max-width: 500px) {
    .common_page .control-datepicker:after {
        right: 15px;
    }
}

.common_page .control-number {
    position: relative;
}

.common_page .control-number-minus, .control-number-plus {
    position: absolute;
    top: 50%;
    display: block;
    transform: translateY(-50%);
    color: #555;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
}

.common_page .control-input-big ~ .control-number-minus, .control-input-big ~ .control-number-plus {
    font-size: 30px;
}

.common_page .control-number-minus {
    left: 10px;
}

.common_page .control-number-plus {
    right: 10px;
}

.common_page .control-number .control-input {
    padding-left: 40px;
    padding-right: 40px;
}

.common_page .control-number .control-input-big {
    padding-left: 50px;
    padding-right: 50px;
}

.common_page .control-checkbox {
    position: relative;
    cursor: pointer;
    font-size: 18px;
    display: inline-flex !important;
    align-items: center;
}

.common_page .control-checkbox input[type="checkbox"], .control-checkbox input[type="radio"] {
    display: none;
}

.common_page .control-checkbox input[type="checkbox"] + i, .control-checkbox input[type="radio"] + i {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: #a3a3a3 1px solid;
    background: #f3f3f3;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 15px;
}

.common_page .control-checkbox input[type="checkbox"]:checked + i:before, .control-checkbox input[type="radio"]:checked + i:before {
    position: absolute;
    display: block;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f99f1e;
    content: '';
}

.common_page .button-dropdown {
    background: #d4d4d4;
    padding: 5px 10px;
    border: 0;
    border-radius: 15px;
    text-align: left;
    line-height: 1;
    position: relative;
}

.common_page .button-dropdown:after {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    z-index: 11;
    transform: translateY(-6px) rotateZ(45deg);
    width: 10px;
    height: 10px;
    border-right: #555 1px solid;
    border-bottom: #555 1px solid;
    box-sizing: border-box;
    content: '';
}

.common_page .button-dropdown:focus:after {
    transform: translateY(0px) rotateZ(-135deg);
}

.common_page .button-dropdown:focus + .dropdown {
    display: block;
    z-index: 10;
}

.common_page .dropdown {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: #d4d4d4;
    padding: 0 10px;
    border-radius: 15px;
    line-height: 1;
    text-align: left;
}

.common_page .dropdown-item {
    padding: 5px 0;
    border-top: #858585 1px solid;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}

.common_page .dropdown-item:first-child {
    border-top: 0;
}

.common_page .lk {
    display: flex;
    margin-top: 86px;
}

.common_page .lk-sidebar {
}

.common_page .lk-content {
    margin-left: 20px;
    flex-grow: 1;
}

@media (max-width: 950px) {
    .common_page .lk {
        margin-top: 56px;
    }
}

@media (max-width: 800px) {
    .common_page .lk {
        display: block;
    }

    .common_page .lk-sidebar {
        margin-bottom: 20px;
    }

    .common_page .lk-content {
        margin-left: 0;
    }
}

.common_page .account-block {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    padding-top: 30px;
}

.common_page .account-block .account-avatar {
    display: block;
    margin: 0 auto 20px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: #c7c7c7 1px solid;
    object-fit: cover;
    object-position: 50% 0;
}

.common_page .account-block .account-menu a {
    display: block;
    position: relative;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    color: #000101;
    text-decoration: none;
    white-space: nowrap;
}

.common_page .account-block .account-menu a:last-child {
    border-radius: 0 0 20px 20px;
}

.common_page .account-block .account-menu a:before {
    display: block;
    position: absolute;
    left: 20px;
    right: 20px;
    top: -1px;
    height: 1px;
    background: #939393;
    content: '';
    z-index: 1;
}

.common_page .account-block .account-menu a:first-child:before {
    display: none;
}

.common_page .account-block .account-menu a:hover, .account-block .account-menu a.active {
    color: #fff;
    background: #f99f1e;
}

.common_page .account-block .account-menu a:hover:before, .account-block .account-menu a.active:before {
    display: none;
}

.common_page .account-block .account-menu a:hover + a:before, .account-block .account-menu a.active + a:before {
    display: none;
}

@media (max-width: 800px) {
    .common_page .account-block {
        display: flex;
        align-items: center;
        padding: 10px 30px;
    }

    .common_page .account-block .account-avatar {
        margin: 0 auto;
    }

    .common_page .account-block .account-menu {
        min-width: 50%;
    }

    .common_page .account-block .account-menu a:hover, .account-block .account-menu a.active {
        border-radius: 10px;
    }
}

@media (max-width: 500px) {
    .common_page .account-block {
        padding: 10px 10px;
    }

    .common_page .account-block .account-menu a {
        padding: 15px;
        white-space: normal;
    }
}

.common_page .orders-block {
    margin-bottom: 30px;
}

.common_page .orders-block .orders-head {
    padding: 5px 10px;
}

.common_page .orders-block .orders-body {
    padding: 5px 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.common_page .orders-block table {
    border-collapse: collapse;
    width: 100%;
}

.common_page .orders-block table tr:last-child td, .orders-block table td[rowspan] {
    border-bottom: 0;
}

.common_page .orders-block table th {
    text-align: center;
    vertical-align: top;
    font-size: 10px;
    font-weight: 500;
    padding: 15px 10px;
}

.common_page .orders-block table td {
    border-bottom: #c5c5c5 1px solid;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    padding: 15px 10px;
}

.common_page .orders-block table td small {
    font-size: 12px;
}

.common_page .orders-block table td.order-num {
    font-size: 18px;
}

.common_page .orders-block table .order-logo img {
    max-width: 100%;
    min-width: 90px;
}

.common_page .orders-block table .order-num {
    width: 90px;
}

.common_page .orders-block table .order-plane {
    width: 130px;
}

.common_page .orders-block table .order-logo {
    width: 90px;
}

.common_page .orders-block table .order-airport {
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.common_page .orders-block table .order-airport div {
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
    margin-right: auto;
}

.common_page .orders-block table .order-date {
    width: 100px;
}

.common_page .orders-block table .order-status {
    width: 90px;
}

@media (max-width: 1200px) {
    .common_page .orders-block.orders-block-lg {
        display: none;
    }
}

.common_page .orders-block.orders-block-sm {
    display: none;
}

.common_page .orders-block.orders-block-sm td {
    border: 0;
    width: 50% !important;
}

.common_page .orders-block.orders-block-sm th {
    border-bottom: #c5c5c5 1px solid;
    width: 50% !important;
}

.common_page .orders-block.orders-block-sm table .order-airport div {
    width: 100%;
}

.common_page .orders-block.orders-block-sm table .order-dir {
    color: #f99f1e;
    font-size: 18px;
    font-weight: bold;
}

.common_page .orders-block.orders-block-sm table .order-status {
    border-top: #c5c5c5 1px solid;
    font-size: 18px;
    font-weight: bold;
}

.common_page .orders-block table .button {
    padding: 10px 30px;
    background: #f99f1e;
    border-color: #f99f1e;
}

@media (max-width: 1200px) {
    .common_page .orders-block.orders-block-sm {
        display: block;
    }
}

@media (max-width: 500px) {
    .common_page .orders-block.orders-block-sm table .order-airport div {
        width: 115px;
    }
}

.common_page .profile-form {
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.common_page .profile-form .form-title {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
}

.common_page .profile-form .form-controls + .form-title {
    margin-top: 40px;
}

.common_page .profile-form .form-controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.common_page .profile-form .form-controls:last-child {
    margin-bottom: 0;
}

.common_page .profile-form .form-controls-2 .form-control {
    width: 49%;
}

.common_page .profile-form .form-controls-3 .form-control {
    width: 32%;
}

.common_page .profile-form .form-control {
    width: 100%;
}

.common_page .profile-form .form-control label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.common_page .profile-form .form-control input {
    display: block;
    width: 100%;
    padding: 15px 20px;
    font-size: 18px;
    background: #f1f1f1;
    border: #e0e0e0 1px solid;
    border-radius: 20px;
    box-sizing: border-box;
}

.common_page .profile-form .form-control .button-dropdown {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 15px 20px;
    font-size: 18px;
    background: #f1f1f1;
    border: #e0e0e0 1px solid;
    border-radius: 20px;
    box-sizing: border-box;
}

.common_page .profile-form .form-control .button-dropdown:after {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-7px) rotateZ(45deg);
}

.common_page .profile-form .form-control .button-dropdown:focus:after {
    transform: translateY(0px) rotateZ(-135deg);
}

.common_page .profile-form .form-control .dropdown {
    top: 50%;
    padding-top: 25px;
    z-index: 0;
}

.common_page .profile-form .form-control .dropdown-item {
    padding: 10px;
}

.common_page .profile-form .form-link {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.common_page .profile-form .form-company-bottom {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.common_page .profile-form .form-company-bottom .control-checkbox {
    margin-right: auto;
}

.common_page .profile-form .form-company-bottom .form-link {
    margin-left: 40px;
}

.common_page .profile-form .form-company-add {
    display: flex;
    align-items: center;
}

.common_page .profile-form .form-company-add .form-title {
    margin: 0 auto 0 0;
}

.common_page .profile-form .form-company-add button {
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background: #f1f1f1;
    border: #e0e0e0 1px solid;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
}

.common_page .profile-form .form-company-add button:before, .profile-form .form-company-add button:after {
    display: block;
    width: 60%;
    height: 2px;
    position: absolute;
    left: 20%;
    top: 50%;
    margin-top: -1px;
    background: #040404;
    content: '';
}

.common_page .profile-form .form-company-add button:after {
    transform: rotateZ(90deg);
}

.common_page .profile-form .icon {
    display: block;
    margin-right: 10px;
    flex-shrink: 0;
}

.common_page .profile-form .icon-foto {
    width: 26px;
    height: 26px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAChUlEQVRIiaWWTYjMYRzHPztRyNgDgyLatYdtZZVMprU5WDnMwWHl6EhuIidSLm57WUfj4jQluShSXg5e2kKbSdoSI8rFS6N1ENbo5/mOnnme//8Zm29N0//38v09L7+Xhx7oA8aAKWAGaAFt/VqSTcmmL0WVUlaB88B24BFwG2gAn6RfDYwC+xToGXAGuBEx5aAI1IEF4CIwkG3WhQHZLsi3GFkEWK9VvwLKkdZhJbALOAEc1ncHZfk2xJWJogweA6XAwI7vLvDNu6M54CPQBDZ4tiVxNPJ2VtdqwiBrgc/Add3bTs/GdvMEuBb4lMRVD4NUdb5Zx3VKq14SaRwsGX4CqwJ5WZzVjsAyb1aXmYUrCZ1huQh3RxrnN9vJbkvNX4nsugOci6TdsJo6EEkdp3GPFYBJ1UkzMnOwBFgRSWNk1WRT3JMWaFzFmAdLhDU5OhTA7udLpHEw7nELNKxUzMMLYEeOzjCoYO8ijYNxD1ugfq+tZOGeWs1ghs6wH/gAvI40DsbdX4jEMWa0q+ORxqX8SeCyijgJy5iDKQPgEPAd2BTIjwHzPe7QuFsFtZLRSN2Nq8BL4Eggt2KeVivKg3HPWaAHwESOUQdt2Y0E8i26wxQm5PunYI1oKGG8EXgLnA3kT9UDl0UeDkPithh/W1AtMoN1Gn7zKrywG28D3utYs+655rcg1PisVVT0bdl0GviqLmyXvjSicbBJewH4Adz0OntFnNXQoa6WsRW4r2w8mujaIUZUnMaxR//RmMAbfG1td3Nk0Rs2n26JI3fw4Y3yN94xLgYV+SZHub+zzuOkphTuBbO5lHqc/Otz66G68HOvOK0bWDHu1dBb9HMrXMj/PyCB364LmJ1BantvAAAAAElFTkSuQmCC");
}

.common_page .profile-form .icon-password {
    width: 24px;
    height: 33px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAhCAYAAADDAmudAAABtUlEQVRIidWWsUtCURTGf0qTS2NFLQ2RNEQmQf9BU4E0NPcXhODU1FJTg0HQZpMFQYMN+i/UFG1CQS4JgYsQmBAYt74nj3df713TAj84+N553/mO99zv6SUGSSAHlIEm0FM0lcuJ8ytkgQcJdoAqcKaoKtcTJztogy0JtIECkLIY37mCOB3VOCGrgicg7VCQFrfjspKkltx2FPc3aas2ck9ymmvBehKPgmpzUcyylho28zikVFuO4hn71aysO2rS6CM4rxmgMUSDhjQSPzW4Am6sMne8ijnpVUwESneGEA9FpKXGooE3ogVgG5i2GINhXew94AJ4NDcbQNf3Szmq6EqbOvACZEY4mYw0jfZXt1OLMjyOjba3yR9/0OCN/3SRCzynGVx7DnGB2YNiDC/otL5DInDg34M4nAAtYFXRUi4Wrg0WgQpwr6gol7CYAbg2MH7eBGaBKV3XNa5IuDY4BOaANWBZ10cWKwSuLrrT574vd2uxQuC6AmPJPLCkyLvadJD3oOhgZwtj/YeT8Bq8A/PW4+GxYrTNHlwCu3p5nkckbr6wOQyfmxtzIiv5juOjCKNVAlKfgHiDWC1tIUIAAAAASUVORK5CYII=");
}

.common_page .profile-form .icon-edit {
    width: 21px;
    height: 21px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABAUlEQVQ4je3UMUqDQRCG4UeLoKWQMgi2YuEFBA/iQTyCpLAXK/tUOYGFSgSLlCK2gpWVqAhBGTI//GQTJRvs/GCKnZl9+ZhZ1grq4gKvmGCEw1WAPTziHNvYwBFeasENsF9UpuBRkf1FDTBiiM5MezierP/MKBxeYoDdzA1mwFt4K24uUOPwpFXupNu247NcXhVwHriffd2iawlgG9zMuVdUK4Cy/g+crz8DxtPYz9jDWi0wdIpnjFvxnuAqYOg2P4O2xum4CriJT+wsgC4NDB3gqchOocMaYOgYH7jKcziOUcSMq4ChcPOV4HAco4gZx/KqgPFk7vGAG1zjLjdfJ3wDdyhQMK/sjqcAAAAASUVORK5CYII=");
}

.common_page .profile-form .icon-delete {
    width: 18px;
    height: 22px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAWCAYAAADNX8xBAAABQklEQVQ4ja2VPU4DQQyFv8AigvjJUqZMTQclLRyBm1DQIAFNCjgBl0CijThEUtKDUm4Q4j8COTiKGXt3E4knjeTxjN88e3a8lOAIGAPfyRjrmsOS8/xiB7gGtpNxCey73UDmPDO8A0XiewOabqdiGKSw6Bg29IQzIAdO3DHV6AIj4DxTuaKqHaRShzXgHnidFrtQRYI9oGcIjoFTM78DdtXOp4dnAdEy0DGBG2YNXWukRJGiIglMkWtd5iJqufAZWqaWtUSS8rqjgE2NqVS0pbl/6E1G6YmaF+DTzB0Rc6SX1oeUCN1QV/C0Pk/6Av4Q2eBRBZGrTxVRmaI8urE6oqhG/6ooJLL9yAY/Al9qP2tnFIjvYRGiC/OerowtHdKqC1MbAIf6YQom1xrYAvnCD4B+kD6rwG1J049+AjfAyiQS+AFaCnI3y7JbeAAAAABJRU5ErkJggg==");
}

@media (max-width: 1000px) {
    .common_page .profile-form .form-controls-2 .form-control {
        width: 100%;
    }

    .common_page .profile-form .form-controls-3 .form-control {
        width: 100%;
    }

    .common_page .profile-form .form-control {
        margin-bottom: 20px;
    }

    .common_page .profile-form .form-control:last-child {
        margin-bottom: 0;
    }

    .common_page .profile-form .form-company-bottom {
        flex-wrap: wrap;
    }

    .common_page .profile-form .form-company-bottom .control-checkbox {
        margin-bottom: 20px;
    }

    .common_page .profile-form .form-company-bottom .control-checkbox + .form-link {
        margin-left: auto;
    }
}

html {
    -webkit-text-size-adjust: none;
    -webkit-appearance: none;
}

html, body.order_page {
    min-width: 330px;
    background: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #000101;
    line-height: 1.2;
}

body {
    overflow-x: hidden;
}

.order_page input, textarea, select, button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #000101;
    -webkit-appearance: none;
}

.order_page input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #999;
}

.order_page input::-moz-placeholder, textarea::-moz-placeholder {
    color: #999;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
}

.order_page input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
    color: #eee;
}

.order_page input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
    color: #eee;
}

.order_page .site-width {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1240px;
    box-sizing: border-box;
}

.order_page .nowrap {
    white-space: nowrap;
}

.order_page .button {
    font-size: 20px;
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    border: #bbb9b9 1px solid;
    background: #d4d3d3;
    border-radius: 25px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}

.order_page button {
    cursor: pointer;
    outline: 0;
}

.order_page button[type="submit"] {
    font-size: 20px;
    display: inline-block;
    padding: 12px 30px;
    color: #fff;
    font-weight: 600;
    border: #f99f1e 1px solid;
    min-width: 200px;
    background: #f99f1e;
    border-radius: 25px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}

.order_page button[type="submit"]:disabled {
    background: #a3a3a3;
    border-color: #a3a3a3;
    cursor: default;
}

.order_page .login-block {
    padding: 15px;
    padding-left: 40px;
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    position: relative;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .login-block ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.order_page .login-block ul li {
    margin: 12px 10px 0 0;
    padding: 0;
    font-size: 11px;
    width: calc(50% - 10px);
}

.order_page .login-block .login-link {
    display: block;
    margin-left: 10px;
    order: 3;
    display: inline-flex;
    align-items: center;
    color: #000101;
    text-decoration: none;
}

.order_page .login-block .login-link:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC8UlEQVRIib2WXWiOYRjHf+/TDmRmaT4jYSvLx1ZEL1uIWlk5MEucSMPkBMm3Ew4kR8iJhgM5YNIcqBEitdmyWphmEQ44UDP1GrGo6er5P553z32/7xz519tb13Xd/+u+r88nVV9fTx6kgGVAHVANlAPFMs8AfUAb0AJ0AMO5qAJHEqMW6BZRGrgLNACr9GuQLC2bbp3xosAjLAKagI3AZb3mvWMVwl5yHJgNHAFuAzeARmAw2zDpaCpwDyjUTbscahgHzAeWA5+BW7qIkV8ErgPtQA3wyRe6IjkZ8jipBB4CP3XTTmAncAboAabLrktnh8RV5HPUpJdYnPuz5JOBR8B3hXGJZFYYs4AB4HyWfb84CsU5InS1ykk64cSwRRW2Hvid0H0DDqsoxgNfs5xt0suvAq2BSvikEu/LyVLgvsdJhHZxLEzIu8Rp3KkC9UmlwuJDCdDrkUf4obyVOBo4Bbw1H4EcPMlTwlYAYx2pi5QjCTmNuy5Qxz9wTGJ8ASY60hgp5SfjaEIYd3Wg6nnhqGNY2BY50hhz5OyDowlh3OWBZteAo45hpV0hQh9qVGXvPDrEXZxv1kXo1Kv2OJqwPfYBV/INVNSwmVFyYDgB7AJmJuTbNbZOOydiGHcm0KivcNQjcRN4A+xIyPcD5zTzcsG4+wKN+DU5jCIMy25eQl6qHOaDcbcFGvVVQFke4xnAWuBZQm47aC8wxjkRokzcLYE2oxEccsxgikbIK+AjcDah3wos1gTf4JwOOY27I1BYjgHbNFRRNR3V+LDheABYmVxmcmAz7o72kP1Pki4tTuMejsq7FWgGrmmpWdwPqnTnAheAX859Q1if7Na8tL30FFghrmZxj9hH0fp9qS1aqX2Sa2on0aut+xp4LK7GyCbb0aC63MIxAZjmUI2OBYpAj7gGfY7Qjq9SgbTrG6D0HxyYzSWdsbPG8fd7weD7CrJbbNZmtIqzRjUCm8IW1qg5reOtGVeL+DmwLspJEv/nAxL4A/TAsmOMOcA8AAAAAElFTkSuQmCC");
}

.order_page .login-block .form {
    text-align: center;
    margin: 0 0 0 auto;
}

.order_page .login-block .form .form-link {
    margin-top: 10px;
    font-size: 14px;
}

.order_page .login-block .form a {
    text-decoration: none;
    font-weight: 600;
    color: #f99f1e;
    font-size: 14px;
}

.order_page .login-block .form a:hover {
    text-decoration: underline;
}

.order_page .login-block .form button[type="submit"] {
    font-size: 18px;
    padding: 12px 30px;
    border-radius: 24px;
    min-width: 190px;
}

@media (max-width: 800px) {
    .order_page .login-block {
        flex-direction: column;
        padding-left: 15px;
    }

    .order_page .login-block .login-link {
        margin-left: auto;
        order: 0;
    }

    .order_page .login-block .login-link:before {
        content: 'Войти';
    }

    .order_page .login-block ul {
        margin-right: 0;
    }

    .order_page .login-block .form {
        margin: 20px auto 0;
    }
}

@media (max-width: 600px) {
    .order_page .login-block ul {
        display: block;
    }

    .order_page .login-block ul li {
        margin-right: 0;
        width: auto;
    }
}

@media (max-width: 400px) {
    .order_page .login-block .login-link {
        margin-bottom: 10px;
    }
}

.order_page .logout-block {
    margin: 20px 0;
    padding: 5px 15px;
    text-align: right;
}

.order_page .logout-block a {
    color: #000101;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.order_page .logout-block a:hover {
    text-decoration: underline;
}

.order_page .logout-block a:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC40lEQVRIiZ2Wy0sXcRTFPzM/e1oJUdBjFRhIQQYRWFoUQQuRFkGiqyGD/gV7bJP2LaKMoB8URQvbhEREFJkKQkVEubGCCAKVMCuoxIk7c37N4ztj2YFZ/O69v3Pmfh/njheGIaWoeh6wBzgKtAFNQIPKZ4BxYAgYAEaALFmQ/PTLNKh67cAzEbUA94Ae4ICeHsVaVGO17Q6P4HZU9VYD/UAncBU4D7xz/pnFFuA0cAK4DZwEZtMdZYWq3gbgPlAPdAFjDiWsArYDe4Ep4A7wVbndwC3gG3CYIPzkCsWdPAV+REsQhJNUvVpdM3BB+7VEMSO3gmmJflR8PTAILANaCcLZ/B71q5NYJIF1+QjYClRyna0E1gCXUvFJ7VW9OEmE4o3vjJYrK2I4LoGNBYfH0ylsl2BarCvijLnxdYT7oo0PwqI9OQiscKJZMXt25uJjOkx9plGndW/WXSnCZqCuIJ7GvDrOw07shGn4EhgmCMuO8C/nIhZjqROOOYdNw9eNf+AUJZjSGy8E28MvJXnjbvNlKy+ddILnwJwTdTHhRGIYd5OvUzPtpBPU7kQZQt2p1yV5424o97oEj4G3wE8nE2NObrDg8vpy4XVOJoERnEs5Qhqh8mecTALjnvFl9TucdBbX9UJ5mF1d1AUtg3GP+7L4QyVFNVREmsdy4KETzcK4h3wNrVaqXqNTEsOWrFtmmce8fG6fkyGytsaIGwZ8TcYXQK9TCJuAG7KSooPjyznMdC/LitLoFfdIPCZi47sb2X0QjlL1rItTwFnZT8WRKMYHYD/wXpPXXKGDIBxMz6ObSnYAV4BdEinqpAyhDoZNgmvAKEHYTY4kHr/wSktWWaQIWrrPWspZccaJklFuTrz2P4S+y/Mm86M8SxQnWvVG3j96XA3m8ja37LoYxx8RVygWs6+XY8ARWU+NxESt/ZobWMzuVu1+manayLH/Rt8JaSz2A3IbYB8xBiN7Azz56wck8Bsa+daUDSJ8VQAAAABJRU5ErkJggg==");
}

.order_page .reserv-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .reserv-block .reserv-h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.order_page .reserv-block .reserv-info {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
    padding: 15px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
}

.order_page .reserv-block .reserv-summary {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
    display: none;
    justify-content: space-between;
}

.order_page .reserv-block .reserv-price {
    white-space: nowrap;
}

.order_page .reserv-block .reserv-h2 a {
    color: #000101;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.order_page .reserv-block .reserv-h2 a:hover {
    text-decoration: underline;
}

.order_page .reserv-block .reserv-h2 a i {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    border-radius: 50%;
    background: #f5f5f5;
    position: relative;
    flex-shrink: 0;
}

.order_page .reserv-block .reserv-h2 a i:before {
    position: absolute;
    left: 40%;
    top: 50%;
    display: block;
    width: 8px;
    height: 8px;
    border: #353535 1px solid;
    border-top: 0;
    border-right: 0;
    transform: translateY(-50%) rotateZ(45deg);
    content: '';
}

@media (max-width: 800px) {
    .order_page .reserv-block {
        padding: 20px 15px;
    }
}

@media (max-width: 600px) {
    .order_page .reserv-block .reserv-info .reserv-price {
        display: none;
    }

    .order_page .reserv-block .reserv-summary {
        display: flex;
    }
}

.order_page .flight-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .flight-block .flight-md {
    display: none;
}

.order_page .flight-block .flight-h1 {
    font-size: 28px;
    font-weight: 600;
    color: #FFA000;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.order_page .flight-block .flight-h1 i {
    display: block;
    width: 27px;
    height: 24px;
    margin-right: 10px;
    content: '';
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAYCAYAAAALQIb7AAABpUlEQVRIib2VTStEURjHf8MoaoQwjNd8AVsbH0EWsrGWppCokayVBStZeImywwdgo6SwlKxGkQViYaO8LFCjZ+bIdJ4z956p4VdTc/7nf+7/Ps85995IZgsfFoBp4BEYAfa8VlmUKcVNv1ETwDbQ4HSF4BsWy/tfDaSUo4Rh99Z4DKhVrhB8w26tsVQ6qVwh+IbdKQUmrPZ6hR0CN8CQmv3FrkyoA8aVGoCE9QBdwArQXMDqChOmgCqlFkDCrs1UDbDstjnbKMSBpFILEDVh3WZ6EBgGzsw4Zjz17uVZUuZhd1VYAWSAUyAtF7qyDBtqSTCtwE6gIxc4k9/GvyQCzEnY6z+ECU8S1qHk0pMGBmTP2q1LbwInwCfwZrRK8wJ28QD0mX15Bz4sz7P5ZU9afmVyB6OOBZ2OkB+WgHOlOpA2tuXJSUeQ0KSUHLLf60otgISVm6lV4Nhto1EpOdZ+WuSDtLHXVBf0CLQoBb5MC72JmlZchixwVbYb8Bpz4vuJSSgFFpUSgm9Y3BofABfKVaIw+xDMFxtUTNi+ORAvwCxwVHQS8A1s1EQaVkakLAAAAABJRU5ErkJggg==");
}

.order_page .flight-block2 .flight-h1 i {
    transform: rotateZ(180deg);
}

.order_page .flight-block .flight-h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.order_page .flight-block .flight-h2 img {
    vertical-align: middle;
    margin-left: 10px;
}

.order_page .flight-block table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.order_page .flight-block table th {
    border-bottom: #a3a3a3 1px solid;
    vertical-align: top;
    font-size: 10px;
    font-weight: 500;
    padding: 10px;
}

.order_page .flight-block table td {
    vertical-align: top;
    font-size: 15px;
    padding: 25px 10px 0;
}

.order_page .flight-block table td small {
    font-size: 12px;
}

.order_page .flight-block .flight-table-2 th, .flight-block .flight-table-2 td {
    width: 50%;
}

.order_page .flight-block .flight-table-3 th, .flight-block .flight-table-3 td {
    width: 33.333%;
}

.order_page .flight-block .flight-more {
    font-size: 16px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.order_page .flight-block .flight-more:after {
    flex-grow: 1;
    display: block;
    margin-left: 10px;
    height: 1px;
    background: #a3a3a3;
    content: '';
}

.order_page .flight-block .flight-more a {
    color: #000101;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.order_page .flight-block .flight-more a:after {
    display: block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    margin-top: -7px;
    transform: rotateZ(-45deg);
    border: #353535 1px solid;
    border-right: 0;
    border-top: 0;
    cursor: pointer;
    content: '';
}

.order_page .flight-block .flight-more.flight-more-active a:after {
    transform: rotateZ(135deg);
    margin-top: 7px;
}

.order_page .flight-block .flight-more + table {
    display: none;
}

.order_page .flight-block .flight-more.flight-more-active + table {
    display: table;
}

.order_page .flight-block .gallery {
    display: flex;
    align-items: center;
}

.order_page .flight-block .gallery-slider {
    position: relative;
    margin-left: 50px;
}

.order_page .flight-block .gallery-slider-inner {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.order_page .flight-block .gallery-slider-inner img {
    display: block;
    width: 32%;
    height: 120px;
    object-fit: cover;
}

.order_page .flight-block .gallery-slider a {
    position: absolute;
    top: 50%;
    display: block;
    width: 10px;
    height: 10px;
    border: #353535 1px solid;
    border-top: 0;
    cursor: pointer;
}

.order_page .flight-block .gallery-slider .gallery-slider-prev {
    left: -20px;
    transform: translateY(-50%) rotateZ(45deg);
    border-right: 0;
}

.order_page .flight-block .gallery-slider .gallery-slider-next {
    right: -20px;
    transform: translateY(-50%) rotateZ(-45deg);
    border-left: 0;
}

@media (max-width: 1200px) {
    .order_page .flight-block .gallery > img {
        display: none;
    }

    .order_page .flight-block .gallery-slider {
        margin: 0 20px;
    }
}

@media (max-width: 900px) {
    .order_page .flight-block .flight-lg {
        display: none;
    }

    .order_page .flight-block .flight-md {
        display: block;
    }

    .order_page .flight-block table {
        margin-bottom: 30px;
    }

    .order_page .flight-block table td {
        padding-top: 15px;
    }
}

@media (max-width: 800px) {
    .order_page .flight-block {
        padding: 20px 15px;
    }
}

@media (max-width: 500px) {
    .order_page .flight-block .gallery-slider-inner img {
        width: 100%;
    }
}

.order_page .noty-block {
    padding: 10px 70px;
    margin: 20px 0;
    border-radius: 20px;
    border: #a3a3a3 1px solid;
    display: flex;
    box-sizing: border-box;
    max-height: 130px;
    overflow: hidden;
    position: relative;
}

.order_page .noty-block > div + div {
    margin-left: 50px;
}

.order_page .noty-block .noty-h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    padding-bottom: 5px;
    position: relative;
}

.order_page .noty-block .noty-h2:after {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    max-width: 320px;
    width: 100%;
    height: 1px;
    background: #a3a3a3;
    content: '';
}

.order_page .noty-block .noty-text {
    font-size: 9px;
    line-height: 1.4;
}

.order_page .noty-block .noty-more {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotateZ(-45deg);
    border: #353535 1px solid;
    border-right: 0;
    border-top: 0;
    cursor: pointer;
}

.order_page .noty-block.noty-block-expanded {
    max-height: unset;
}

.order_page .noty-block.noty-block-expanded .noty-more {
    transform: rotateZ(135deg);
    bottom: 12px;
}

@media (max-width: 800px) {
    .order_page .noty-block {
        display: none;
    }
}

.order_page .order-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order_page .order-block .rules {
    display: flex;
    margin-bottom: 40px;
}

.order_page .order-block .rules-company {
    padding-left: 40px;
    position: relative;
}

.order_page .order-block .rules-company:before {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: #f5f5f5;
    content: '';
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==");
}

.order_page .order-block .rules-company + .rules-company {
    margin-left: 60px;
}

.order_page .order-block .rules-h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: -40px;
    display: flex;
    align-items: center;
}

.order_page .order-block .rules-h2:before {
    display: block;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 8px;
    background: #f5f5f5;
    flex-shrink: 0;
    content: '';
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==");
}

.order_page .order-block .rules-link {
    font-size: 11px;
    margin-top: 5px;
}

.order_page .order-block .rules-link a {
    color: #000101;
    text-decoration: underline;
}

.order_page .order-block .continue {
    display: flex;
    justify-content: space-between;
}

.order_page .order-block .continue a {
    color: #000101;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.order_page .order-block .continue a:first-child {
    margin-right: 10px;
}

.order_page .order-block .continue a:last-child {
    margin-left: 10px;
    text-align: right;
}

.order_page .order-block .continue a:hover {
    text-decoration: underline;
}

.order_page .order-block .continue a i {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f5f5f5;
    position: relative;
    flex-shrink: 0;
}

.order_page .order-block .continue a:first-child i {
    margin-right: 20px;
}

.order_page .order-block .continue a:last-child i {
    margin-left: 20px;
}

.order_page .order-block .continue a i:before {
    position: absolute;
    top: 50%;
    display: block;
    width: 8px;
    height: 8px;
    border: #353535 1px solid;
    border-top: 0;
    content: '';
}

.order_page .order-block .continue a:first-child i:before {
    left: 40%;
    transform: translateY(-50%) rotateZ(45deg);
    border-right: 0;
}

.order_page .order-block .continue a:last-child i:before {
    right: 40%;
    transform: translateY(-50%) rotateZ(-45deg);
    border-left: 0;
}

.order_page .order-block .form {
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .order_page .order-block {
        padding: 20px 15px;
    }
}

@media (max-width: 700px) {
    .order_page .order-block .rules {
        display: block;
        margin-bottom: 20px;
    }

    .order_page .order-block .rules-company:before {
        display: block;
    }

    .order_page .order-block .rules-company + .rules-company {
        margin-left: 0;
        margin-top: 20px;
    }

    .order_page .order-block .rules-h2 {
        margin-left: 0;
        margin-bottom: 5px;
    }

    .order_page .order-block .rules-h2:before {
        display: none;
    }
}

@media (max-width: 700px) {
    .order_page .order-block .continue a {
        font-size: 16px;
    }
}

.order_page .payments {
    margin-bottom: 30px;
    padding-left: 75px;
}

.order_page .payments .payments-item {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: #c5c5c5 1px solid;
    position: relative;
}

.order_page .payments .payments-item b {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.order_page .payments .payments-item b:last-child {
    margin-bottom: 10px;
}

.order_page .payments .payments-item a {
    color: #000101;
    text-decoration: none;
    margin-left: 30px;
    display: inline-block;
}

.order_page .payments .payments-item a:hover {
    text-decoration: underline;
}

.order_page .payments .payments-item img {
    display: block;
    position: absolute;
    left: -75px;
    top: 0;
}

.order_page .payments .payments-item small {
    display: block;
    color: #868686;
    margin-bottom: 10px;
    font-size: 10px;
}

.order_page .payments .payments-item input {
    display: block;
    color: #424242;
    font-size: 12px;
    border: 0;
    background: #eee;
    border-radius: 5px;
    padding: 10px;
    flex-grow: 1;
}

@media (max-width: 500px) {
    .order_page .payments .payments-item.payments-item-link b {
        display: block;
    }

    .order_page .payments .payments-item.payments-item-link input + a {
        margin: 5px 0 0 0;
    }
}

.order_page .form {
}

.order_page .form .form-control {
    margin-bottom: 20px;
}

.order_page .form .form-checkbox {
    display: flex;
    justify-content: space-between;
}

.order_page .form .form-checkbox label {
    font-size: 16px;
}

.order_page .form .form-buttons {
    display: inline-block;
}

.order_page .form .form-submit {
    text-align: center;
    margin-top: 30px;
}

.order_page .form .form-submit button {
    min-width: 260px;
}

.order_page .form .form-policy label {
    font-size: 11px;
    align-items: center;
}

.order_page .form .form-policy a {
    color: #000101;
    text-decoration: underline;
}

.order_page .form button + button {
    margin-left: 10px;
}

.order_page .form .form-links {
    display: inline-block;
    float: right;
    margin-top: 10px;
}

.order_page .form .form-links a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #000101;
}

.order_page .form .form-links a + a {
    margin-left: 30px;
}

.order_page .form .form-links a:hover {
    text-decoration: underline;
}

.order_page .form .form-links a:before {
    flex-shrink: 0;
    margin-right: 10px;
    width: 33px;
    height: 33px;
    display: block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    content: '';
}

.order_page .form .form-links a.form-links-share:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAaCAYAAACtv5zzAAABgUlEQVRIibXWwUtUURTH8Y9agpBkrURECnQjgX9Bf0DUX5AuXEcboZWbclWu/AOildAikRbtok3rdi2i0IWuTBc1mZKEWRw4wmteMvnmzhced85wz2/m3Pe791wFmcZbnKCFxxgoJT+Cz/iNj/ian5drMxsyn4JPM300q2j1F/qBSzl+ynEP3zBcm3lOhrCI/awgRB/hZcavmwr3YQ7bKXSA5zjOOJ4NXK9l/gc38S5FwjHPMJZpE7iLWxg8r/AU1iv/8A1marMacBUr+JnCH3C7idA4buBixlHiAr6kcDjjHi7UMjtwGa8qpe+kMzYz/oEnOa8Rqyn0HmttTghnXOt2jY+wm54OllJ8sTazAac7uS8flbEYqxV3xA78VXqJznrJGxkfdfuST+mZTTtx5R8b7U6HnEZM9uqoaKdnh12VsPJs5bg+LHlcV2lvONEmH+JFtw2nnfsp+CC/jwq3Yk+V6snR0YK4ugTR9MN93wvp/3VtibWP3lz02hLUL14M/AHNFHsGsLHuDAAAAABJRU5ErkJggg==");
}

.order_page .form .form-links a.form-links-print:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAB00lEQVRYhe2Yv0rEQBDGf6OCCnLYKhaWgmDnaSOoiL1PEBBszkYQRKzOB1AEO+HAztJWLBSxtBNEn+AEG8HGxnAyMisx2ZiLJnrFfTAkl83OfJlv/8weIpJmhyISikirAAvNny+OmuCBPnwDboErT3tezALTwBDwmujrYeVMv+DA8/wnVjd/w76+PQlW/4AuCYcuCYeOIOGmSUVE9kSkWdDilGVNi1dxi1UFuAamgEtboMqGxlqwWHMYI2UX+BaSEi2wuBpfmsADsPgPo+ECmNCBOVKCBJPAjl0VvcCaWW/kvRuN35foXgzOgVFgHRgDVoEj8yyR+4/NrIwp6tuWfc8+UUYmWsAysAKc2u9GpL0R71CWHHdmDmFEggS6y7ZDx5M4NC1bBVho/rxIG5g6pWoFF7rqb8tX6KaRaFmWlMBGojU/6sAM0O8j8Z0ccdTakGU90asNpGXCB93m92NrfxShbUh5MICReLT9PQv3wGbGO3lR1fgqx4kVGEGGg3bkyCNNYHFPNBO7wBJwbA03vsHThhxRpEmzbddqpLLazaoxiz4GOvtSY7qB+WJ6qw3aVHpOfMfvMQ48xTOddipXaEpVmrMCgs9/FLQ/OJX/zf8TIrwD0rK9GSISrn4AAAAASUVORK5CYII=");
}

.order_page .form .form-bottom {
    font-size: 10px;
    margin-top: 30px;
    line-height: 1.4;
}

.order_page .form .form-bottom a {
    color: #000101;
    text-decoration: none;
}

.order_page .form .form-bottom a:hover {
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .order_page .form .form-links {
        display: block;
        float: none;
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .order_page .form .form-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .order_page .form button + button {
        margin: 20px 0 0 0;
    }

    .order_page .form .form-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .order_page .form .form-links a + a {
        margin: 20px 0 0 0;
    }
}

.order_page .control-checkbox {
    position: relative;
    cursor: pointer;
    font-size: 18px;
    display: inline-flex !important;
    align-items: center;
}

.order_page .control-checkbox input[type="checkbox"], .control-checkbox input[type="radio"] {
    display: none;
}

.order_page .control-checkbox input[type="checkbox"] + i, .control-checkbox input[type="radio"] + i {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: #a3a3a3 1px solid;
    background: #f3f3f3;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 10px;
}

.order_page .control-checkbox input[type="checkbox"]:checked + i:before, .control-checkbox input[type="radio"]:checked + i:before {
    position: absolute;
    display: block;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f99f1e;
    content: '';
}

.order_page .modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    align-items: center;
    justify-content: center;
}

.order_page .modal.modal-active {
    display: flex;
}

.order_page .modal:before {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    content: '';
}

.order_page .modal .modal-window {
    border-radius: 20px;
    position: relative;
    max-width: 95%;
    max-height: 90%;
    min-width: 400px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    overflow: auto;
}

.order_page .modal .modal-body {
    background: #fff;
    border-radius: 20px;
    padding: 40px 100px;
    box-sizing: border-box;
    position: relative;
    font-size: 13px;
}

.order_page .modal .modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 21px;
    height: 21px;
    z-index: 1;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAB2UlEQVQ4ja3VTYjNYRTH8c9cTCiyUDIbZSVZ2SgpSUpThDBS5CVFmY23QnbTKG8r4yWJvJSkKJlQQlazuSvKSrEYWSiljJdm6OTc5vb/32vuNH67+5xzv/c55/zOc9uq1epCXMFWvDcxzcXHCs5hKV5i/gSQa/AOewO6BQOYh1dYUEofWxtxD1OxKKBfsCqBHXgRgXEAo2130I6z6K5k4CtW4xnm4DkWl75e1m7cwCScxCH8rtSlfcu+9GN2/sCSEmZU+3LAATyBY7VIpZA4hPV4gFl4imUlHAfQhzYcQU99sAgN/cRm3MVMPMHKuvjR7F2oG6eLgMkl5F/9ygF8x3Y8xIa0XpQ6EtbJ8ktqBg0NYyd+YA8eZWVxvisH1FCNyq9X7Uav63Iv/guoBWjEL6VvR/Ispr6tlNkiNKxyLUsfSrv15Pn1PG+oZj2dgpvoSv+uTd/25+deXM4t6mvlpu1pp67CptVU25zQeRweCzoN97Gu8CYUFT7dHyuJUzjeDDo9/diJz2n4gRJuVBeyr8PZ694idAYeJ+gTVqBawpR1FTsSHJt2JlY3BhU7HsB4PAYT/LYFYE23cvNu42C8qXHTeAsD+AHLxwmsKR7oTbl9b/73f1QHBv8AmJdqImTRS7QAAAAASUVORK5CYII=");
}

.order_page .modal .modal-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.order_page .modal .modal-subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .order_page .modal .modal-body {
        padding: 40px;
    }

    .order_page .modal .modal-close {
        top: 15px;
        right: 15px;
    }
}

@media (max-width: 450px) {
    .order_page .modal .modal-window {
        min-width: unset;
    }

    .order_page .modal .modal-body {
        padding: 40px 15px;
    }

    .order_page .modal .modal-body button[type="submit"] {
        font-size: 20px;
        padding: 15px 20px;
        border-radius: 28px;
    }
}

.hidden {
    display: none !important;
}

body header {
    background: #f5f5f5;
}

.form-block .tabs-body {
    border-radius: 20px !important;
}

.flights-body:hover {
    background: #f99f1e;
}

.common_page.main, .order_page.main {
    margin-top: 92px;
}

.common_page .form .form-control input[type="radio"] {
    width: auto;
    padding: 0;
    margin: 0;
    border: 0;
}

.modalable {
    overflow: hidden;
}

.main.marginless {
    margin-top: 20px;
}

.empty-result {
    font-weight: 100;
    text-align: center;
}

.common_page .account-block .account-menu a:first-child {
    border-radius: 20px 20px 0 0;
}

.common_page .account-block {
    padding-top: 0;
}

.hidden {
    display: none !important;
}

body header {
    background: #f5f5f5;
}

.form-block .tabs-body {
    border-radius: 20px !important;
}

.flights-body:hover {
    background: #f99f1e;
}

.common_page.main, .order_page.main {
    margin-top: 92px;
}

.common_page .form .form-control input[type="radio"] {
    width: auto;
    padding: 0;
    margin: 0;
    border: 0;
}

.modalable {
    overflow: hidden;
}

.main.marginless {
    margin-top: 20px;
}

.empty-result {
    font-weight: 100;
    text-align: center;
}

.common_page .account-block .account-menu a:first-child {
    border-radius: 20px 20px 0 0;
}

.common_page .account-block {
    padding-top: 0;
}